import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {updateFormState} from "../hostSlice";
import {Field} from "../../../form/Field";
import "./Page3Contact.scss"
import {useHistory} from "react-router-dom";

export function Page3Contact(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const host = props.host

  useEffect(() => {
    document.getElementsByClassName('contentArea')[0].scroll({top: 0})
  }, [])

  const formMethods = useForm({
    defaultValues: props.defaultValues,
  })
  const {handleSubmit} = formMethods

  const [submitAction, setSubmitAction] = useState()
  const onSubmit = values => {
    dispatch(updateFormState(host, values))
    if (submitAction === "back")
      history.push("../page2/")
    else
      history.push("../page4/")
  }

  return (
      <FormProvider {...formMethods}>
        <form className={"form Page3Contact"} onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={"formSection contactFieldSet"}>
            <h3>How can we get in touch?</h3>
            <div className={"helpText"}>These details won't be posted publicly</div>
            <Field
                type={"email"}
                label={"Email"}
                name={"email"}
                helpText={"Emails for this listing specifically will go to this address"}
                required={true}
            />
            <Field
                label="Phone number"
                name={"phone"}
            />
          </fieldset>

          <div className={"field pageControls"}>
            <div>
              <button onClick={() => setSubmitAction("back")}>back</button>
              <button onClick={() => setSubmitAction("next")}>next</button>
            </div>
            {props.onCancel && (
              <div>
                <button className={"cancel"} onClick={(e) => {
                  props.onCancel()
                  e.preventDefault();
                }}>
                  cancel
                </button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
  );
}
