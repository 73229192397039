import {createSlice} from '@reduxjs/toolkit'
import {client} from "../../api/client";
import {selectAuthenticated, selectToken} from "../auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

export const businessesSlice = createSlice({
  name: 'businesses',
  initialState: {
    businessesLoading: false,
    businessUpdating: false,
    businesses: null,
    errors: null,
  },
  reducers: {
    // businesses
    setBusinessesLoading: state => {
      state.businessesLoading = true
    },
    setBusinesses: (state, action)  => {
      state.businesses = action.payload
      state.businessesLoading = false
    },

    // business create/update
    setBusinessUpdating: state => {
      state.businessUpdating = true
    },
    addBusiness: (state, action) => {
      const business = action.payload;
      const businessIndex = state.businesses.findIndex(b => business.id === b.id);
      if (businessIndex !== -1)
        state.businesses[businessIndex] = business
      else
        state.businesses.push(business)
      state.businessUpdating = false
    },
    businessUpdateError: (state, action) => {
      state.businessUpdating = false
      state.errors = action.payload
    },
  }
})

const { setBusinessesLoading, setBusinesses, setBusinessUpdating, addBusiness, businessUpdateError } = businessesSlice.actions

const fetchBusinesses = () => async (dispatch, getState) => {
  console.log("fetching businesses")
  dispatch(setBusinessesLoading())
  let response = await client.get('hosts/businesses/', selectToken(getState()))
  dispatch(setBusinesses(response))
}

export const performCreateBusiness = data => async (dispatch, getState) => {
  console.log(`performing business create`)
  dispatch(setBusinessUpdating())
  try {
    let response = await client.post('hosts/businesses/', data, selectToken(getState()))
    dispatch(addBusiness(response))
  } catch (e) {
    dispatch(businessUpdateError(e.data))
  }
}

export const performUpdateBusiness = (id, data) => async (dispatch, getState) => {
  console.log(`performing business update`)
  dispatch(setBusinessUpdating())
  try {
    let response = await client.put(`hosts/businesses/${id}/`, data, selectToken(getState()))
    dispatch(addBusiness(response))
  } catch (e) {
    dispatch(businessUpdateError(e.data))
  }
}

export const selectBusinesses = state => {
  return state.businesses.businesses;
}
export const selectHasBusiness = state => selectBusinesses(state)?.length > 0
export const selectBusinessesLoading = state => state.businesses.businessesLoading
export const selectBusinessUpdating = state => state.businesses.businessUpdating
export const selectErrors = state => state.businesses.errors

export const useBusinesses = () => {
  const dispatch = useDispatch()
  const authenticated = useSelector(selectAuthenticated)
  const businesses = useSelector(selectBusinesses)
  const hasBusiness = useSelector(selectHasBusiness)
  const loading = useSelector(selectBusinessesLoading)

  useEffect(() => {
    if (authenticated && businesses === null && loading === false)
      dispatch(fetchBusinesses())
  }, [dispatch, authenticated, businesses, loading])

  return { businesses, hasBusiness, loading }
}

export default businessesSlice.reducer
