import React from "react";
import {CreateUpdateHost} from "./CreateUpdateHost/CreateUpdateHost";
import {useHistory, useLocation} from "react-router-dom";
import "./CreateHost.scss"

export const CreateHost = () => {
  const history = useHistory()
  const location = useLocation()
  const next = location.state?.next

  return (
      <div className={"CreateHost contentArea"}>
        <div className={`content`}>
          <CreateUpdateHost onSave={(host) => {
            if (host.payment_method === 'PAYPAL') {
              window.location.href = host.subscription.links[host.subscription.links.findIndex(l => l.rel === "approve")].href
            } else {
              if (next) history.push(next)
              else history.push('/')
            }
          }} onCancel={next ? () => {
            history.push(next)
          }: null} />
        </div>
      </div>
  )
}
