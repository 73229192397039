import {createSlice} from "@reduxjs/toolkit";
import {client} from "../../api/client";
import {selectToken} from "../auth/authSlice";
import {selectBusiness, useBusiness} from "../business/businessSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

export const DAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
]

export const PAYPAL = "PAYPAL"
export const INVOICE = "INVOICE"

export const range = (n, stop, step=1) => {
  if (stop === undefined) {
    stop = n
    n = 0
  }
  const result = []
  while (n < stop) {
    result.push(n)
    n += step
  }
  return result
}

export const HOURS = range(24);

export const ALL_HOURS = Object.fromEntries(DAYS.map((_, day) => {
  return [day, Object.fromEntries(HOURS.map((hour) => [hour, true]))]
}));

export const isDayTime = hour => hour >= 7 && hour < 19
export const isNightTime = hour => !isDayTime(hour)

export const allNightTimeHoursSelected = (swurfTimes) => DAYS.map((_, day) => day).reduce((daySelected, day) => {
  return daySelected && HOURS.filter(isNightTime).reduce((hourSelected, hour) => {
    return hourSelected && (swurfTimes[day] && swurfTimes[day][hour])
  }, true)
}, true)

export const allHoursSelected = (swurfTimes) => DAYS.map((_, day) => day).reduce((daySelected, day) => {
  return daySelected && HOURS.reduce((hourSelected, hour) => {
    return hourSelected && swurfTimes[day] && swurfTimes[day][hour]
  }, true)
}, true)

export const anyHoursSelected = (swurfTimes) => DAYS.map((_, day) => day).reduce((daySelected, day) => {
  return daySelected || HOURS.reduce((hourSelected, hour) => {
    return hourSelected || (swurfTimes[day] && swurfTimes[day][hour])
  }, false)
}, false)

export const parseTime = (s) => {
  const [hours, minutes, seconds] = s.split(":").map(s => Number.parseInt(s.trim()))
  return {hours, minutes, seconds}
}

export const apiToDict = (data) => Object.fromEntries(DAYS.map((_, day) => {
  const hours = {}
  data.filter(item => item.weekday === day).forEach(item => {
    const start = parseTime(item.start).hours
    const endTime = parseTime(item.end)
    const end = endTime.minutes > 0 ? endTime.hours +1 : endTime.hours
    range(start, end).forEach(hour => {
      hours[hour] = true
    })
  })
  return [day, hours]
}))

export const dictToApi = (swurfTimes) => {
  const data = []
  DAYS.forEach((_, i) => {
    const day = swurfTimes[i] || {}
    let start = null;
    let end = null;

    const createItem = () => {
      data.push({
        weekday: i,
        start: `${String(start).padStart(2, "0")}:00:00`,
        end: end === 24 ? "23:59:59" : `${String(end).padStart(2, "0")}:00:00`,
      })
      start = end = null
    }

    HOURS.forEach(hour => {
      if (day[hour]) {
        if (start !== null) {
          if (end === hour) {
            end = hour + 1
          } else {
            createItem()
          }
        }
        if (start === null) {
          start = hour
          end = hour + 1
        }
      }
    })
    if (start !== null)
      createItem()
  })
  return data
}

export const hostSlice = createSlice({
  name: 'host',
  initialState: {
    isLoading: false,
    hosts: null,
    errors: null,
    forms: {},
  },
  reducers: {
    setLoading: state => {
      state.isLoading = true
    },
    setHosts: (state, action)  => {
      state.hosts = action.payload
      state.isLoading = false
    },
    addHost: (state, action) => {
      const host = action.payload;
      const index = state.hosts.findIndex(b => host.id === b.id);
      if (index !== -1)
        state.hosts[index] = host
      else
        state.hosts.push(host)
      state.isLoading = false
    },
    error: (state, action) => {
      state.isLoading = false
      state.errors = action.payload
    },
    setFormState: (state, action) => {
      const {id, data} = action.payload
      if (data === undefined) {
        state.forms[id] = undefined
      } else {
        const currentValues = state.forms[id] || {}
        state.forms[id] = {...currentValues, ...data}
      }
    }
  }
})

const { setLoading, setHosts, addHost, error, setFormState } = hostSlice.actions

const fetchHosts = () => async (dispatch, getState) => {
  const state = getState();
  const business = selectBusiness(state);
  console.log(`fetching hosts for ${business.name}`)
  dispatch(setLoading())
  let response = await client.get(`hosts/businesses/${business.id}/hosts/`, selectToken(state))
  dispatch(setHosts(response))
}

export const updateFormState = (host, data) => async (dispatch, getState) => {
  const id = host ? host.id : "new";
  dispatch(setFormState({id, data}))
  return formDataByHost(selectForms(getState()), host)
}

export const clearFormState = host => async (dispatch, getState) => {
  const id = host ? host.id : "new";
  dispatch(setFormState({id, data: undefined}))
  return formDataByHost(selectForms(getState()), host)
}

export const performCreateHost = data => async (dispatch, getState) => {
  console.log(`performing host create`)
  const state = getState();
  const business = selectBusiness(state);
  dispatch(setLoading())
  try {
    let response = await client.post(`hosts/businesses/${business.id}/hosts/`, data, selectToken(state))
    dispatch(addHost(response))
    return response
  } catch (e) {
    dispatch(error(e.data))
    throw e
  }
}

export const performUpdateHost = (id, data) => async (dispatch, getState) => {
  console.log(`performing host update`)
  const state = getState();
  const business = selectBusiness(state);
  dispatch(setLoading())
  try {
    let response = await client.put(`hosts/businesses/${business.id}/hosts/${id}/`, data, selectToken(state))
    dispatch(addHost(response))
    return response
  } catch (e) {
    dispatch(error(e.data))
    throw e
  }
}

export const validateURL = (url) => async (dispatch, getState) => {
  const state = getState()
  try {
    await client.post('hosts/validate-url/', {url}, selectToken(state))
    return true
  } catch (e) {
    return false
  }
}

const selectHosts = state => state.host.hosts
const selectHasHost = state => {
  const hosts = selectHosts(state)
  if (hosts === null)
    return null
  return hosts.length > 0
}
export const selectIsLoading = state => state.host.isLoading
export const selectErrors = state => state.host.errors
export const selectForms = state => state.host.forms

export const formDataByHost = (forms, host) => forms[host ? host.id : "new"]
export const hostById = (hosts, id) => {
  if (hosts === null)
    return null
  return hosts[hosts.findIndex(h => parseInt(id) === h.id)]
}

export const useHosts = () => {
  const dispatch = useDispatch()
  const business = useBusiness()
  const hosts = useSelector(selectHosts)
  const hasHost = useSelector(selectHasHost)

  useEffect(() => {
    if (business && hosts === null)
      dispatch(fetchHosts())
  }, [dispatch, business, hosts])
  return {business, hosts, hasHost}
}

export default hostSlice.reducer
