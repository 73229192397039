import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import "./Field.scss";
import { useFormContext } from "react-hook-form";

export const Radio = ({
  label,
  name,
  helpText,
  options = [],
  required = false,
  registerOptions = {},
  onChange,
}) => {
  const { errors, register } = useFormContext();

  if (required && registerOptions.required === undefined)
    registerOptions.required =
      required === true ? "This field is required" : required;

  return (
    <div
      className={`field ${errors.name ? "error" : ""} ${
        required ? "required" : ""
      } Radio`}
    >
      <label>
        <span className={"fieldLabel"}>
          <span className={"labelText"}>{label}</span>
          {helpText && <span className={"helpText"}>{helpText}</span>}
        </span>
      </label>
      <span className={"fieldInput"}>
        {options.map(({ label, description, value }) => (
          <label key={value}>
            <input
              type={"radio"}
              name={name}
              value={value}
              ref={register(registerOptions)}
              onChange={() => {
                onChange && onChange(value); // Call the provided onChange callback if it exists
              }}
            />
            <div className={"label"}>
              <div className={"labelContent"}>
                <div className={"name"}>{label}</div>
                {description && (
                  <div className={"description"}>{description}</div>
                )}
              </div>
            </div>
          </label>
        ))}
      </span>
      <ErrorMessage
        className="fieldError"
        name={name}
        as="div"
        errors={errors}
      />
    </div>
  );
};
