import React, {useEffect, useState} from "react";
import {CreateUpdateHost} from "./CreateUpdateHost/CreateUpdateHost";
import {useHistory, useLocation} from "react-router-dom";
import "./EditHost.scss"
import {hostById, useHosts} from "./hostSlice";
import {useParams} from "react-router";

export const EditHost = () => {
  const {hosts} = useHosts()
  const {id} = useParams()
  const [host, setHost] = useState()
  const history = useHistory()
  const location = useLocation()
  const next = location.state?.next

  useEffect(() => {
    setHost(hostById(hosts, id))
  }, [hosts, setHost, id])

  return (
      <div className={"EditHost contentArea"}>
        <div className={`content`}>
          {host && (  // do not render until host loaded, as default values are cached on first render
            <CreateUpdateHost
                host={host}
                onSave={() => {
                  if (next) history.push(next)
                  else history.push('/')
                }}
            />
          )}
        </div>
      </div>
  )
}
