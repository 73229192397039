import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {
  ALL_HOURS,
  allHoursSelected,
  anyHoursSelected,
  apiToDict,
  DAYS,
  dictToApi,
  HOURS,
  parseTime,
  updateFormState
} from "../hostSlice";
import "./Page4Times.scss"
import {useHistory} from "react-router-dom";
import {SwurfTimes} from "./SwurfTimes";

const ALL_DAY = "ALL_DAY";
const CLOSED = "CLOSED";

export function Page4Times(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const host = props.host
  const initialSwurfTimes = props.defaultValues.swurf_times ? apiToDict(props.defaultValues.swurf_times) : ALL_HOURS;
  const [swurfTimes, setSwurfTimes] = useState(initialSwurfTimes)
  const [openingTimesError, setOpeningTimesError] = useState(false)

  useEffect(() => {
    document.getElementsByClassName('contentArea')[0].scroll({top: 0})
  }, [])

  const defaultValues = {
    ...props.defaultValues,
    restricted: allHoursSelected(initialSwurfTimes) || !anyHoursSelected(initialSwurfTimes) ? "false" : "true",
  }

  const openDays = props.defaultValues.opening_times?.map(item => {
    if (item.start === "00:00:00" && item.end === "23:59:59") {
      defaultValues[`open_${item.weekday}`] = ALL_DAY
    } else {
      const start = `${String(parseTime(item.start).hours).padStart(2, "0")}:00`
      const end = `${String(parseTime(item.end).hours).padStart(2, "0")}:00`
      defaultValues[`open_${item.weekday}`] = start
      defaultValues[`close_${item.weekday}`] = end
    }
    return item.weekday
  }) || []

  DAYS.forEach((day, i) => {
    if (openDays.indexOf(i) < 0) {
      defaultValues[`open_${i}`] = CLOSED
    }
  })

  const formMethods = useForm({
    defaultValues: defaultValues,
  })
  const {handleSubmit, register, watch} = formMethods

  const [submitAction, setSubmitAction] = useState()
  const onSubmit = (values) => {
    const openingTimes = DAYS.map((day, weekday) => {
      const open = values[`open_${weekday}`]
      if (open === CLOSED)
        return null

      let start = "00:00:00"
      let end = "23:59:59"
      if (open !== ALL_DAY) {
        start = open + ":00"
        end = values[`close_${weekday}`] + ":00"
      }
      return {weekday, start, end}
    }).filter(item => item !== null)

    if (openingTimes.length === 0) {
      setOpeningTimesError("You must choose at least one time below")
    } else {
      setOpeningTimesError(null)
      dispatch(updateFormState(host, {
        opening_times: openingTimes,
        swurf_times: dictToApi(swurfTimes),
      }))
      if (submitAction === "back")
        history.push("../page3/")
      else
        history.push("../page5/")
    }
  }

  return (
      <FormProvider {...formMethods}>
        <form className={"form Page4Times"} onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={"formSection"}>
            <h3>Opening times</h3>
            {openingTimesError && (
              <div className={"field"}>
                <div className="fieldError">You must choose at least one time below</div>
              </div>
            )}
            <div className={"openingTimes"}>
              {DAYS.map((day, i) => (
                <div key={i} className={"day"}>
                  <label>
                    <span className={"fieldLabel"}>{day}:</span>
                    <select name={`open_${i}`} ref={register()}>
                      <option value={ALL_DAY}>24 hours</option>
                      <option value={CLOSED}>Closed</option>
                      {HOURS.map((hour) => (
                        <option key={hour} value={`${String(hour).padStart(2, "0")}:00`}>{String(hour).padStart(2, "0")}:00</option>
                      ))}
                    </select>
                    {(() => {
                      const open = watch(`open_${i}`);
                      const openHour = parseTime(open || "00:00:00").hours
                      const hidden = open === CLOSED || open === ALL_DAY
                      return (
                          <React.Fragment>
                            {!hidden && (
                                <React.Fragment>&nbsp;to&nbsp;</React.Fragment>
                            )}
                            <select name={`close_${i}`} className={`${hidden && 'hidden'}`} ref={register()}>
                              {HOURS.filter(hour => hour > openHour).map((hour) => (
                                <option key={hour} value={`${String(hour).padStart(2, "0")}:00`}>{String(hour).padStart(2, "0")}:00</option>
                              ))}
                            </select>
                          </React.Fragment>
                      )
                    })()}
                  </label>
                </div>
              ))}
            </div>
            <SwurfTimes initialSwurfTimes={initialSwurfTimes} swurfTimes={swurfTimes} setSwurfTimes={setSwurfTimes} />
          </fieldset>

          <div className={"field pageControls"}>
            <div>
              <button onClick={() => setSubmitAction("back")}>back</button>
              <button onClick={() => setSubmitAction("next")}>next</button>
            </div>
            {props.onCancel && (
              <div>
                <button className={"cancel"} onClick={(e) => {
                  props.onCancel()
                  e.preventDefault();
                }}>
                  cancel
                </button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
  );
}
