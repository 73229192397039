import {useSelector} from "react-redux";
import {selectIsLoading, useHosts} from "./hostSlice";
import {HostListItem} from "./HostListItem";
import React from "react";
import {SwurfSpinner} from "../../components/SwurfSpinner";

export const HostList = () => {
  const {hosts} = useHosts()
  const loading = useSelector(selectIsLoading)

  return (
      <div className={"HostList"}>
        <SwurfSpinner loading={loading} />
        {hosts?.map((host) => (
            <HostListItem key={host.id} host={host} />
        ))}
      </div>
  )
}
