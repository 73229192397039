import React from 'react'
import {Link, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import wordmark from "../../assets/WordmarkViolet.svg"
import "./Navbar.scss"
import {performLogout, selectUser} from "../auth/authSlice";
import {useBusinesses} from "../business/businessesSlice";
import PropTypes from "prop-types";
import {useBusiness} from "../business/businessSlice";

export const Navbar = ({closed, itemClick, closeClick}) => {
  const dispatch = useDispatch()
  const {hasBusiness} = useBusinesses()
  const business = useBusiness()
  const user = useSelector(selectUser)
  const isAdmin = user.business_users.find(b => b.business === business?.id)?.is_admin
  return (
      <nav className={`Navbar ${closed && "closed"}`}>
        <div className={"closeButton"} onClick={closeClick} />
        <div className={"logo"}><h1><Link onClick={itemClick} to="/"><img src={wordmark} alt={"SWURF"} /></Link></h1></div>
        {user.business_users.first}
        <ul className={"menu"}>

          {hasBusiness ? (
              <React.Fragment>
                <li>
                  <NavLink onClick={itemClick} to={"/"} exact>Listings</NavLink>
                </li>
                {isAdmin && (
                  <li>
                    <NavLink onClick={itemClick} to={"/business/edit/"} exact>Profile</NavLink>
                  </li>
                )}
              </React.Fragment>
          ): (
              <React.Fragment>
                <li>
                  <NavLink onClick={itemClick} to={"/"} exact>Registration</NavLink>
                </li>
              </React.Fragment>
          )}

          <li>
            <NavLink onClick={itemClick} to={"/account/"} exact>Account</NavLink>
          </li>

          <li className={"logout"}>
            <Link to={"/"}
                onClick={() => dispatch(performLogout())}>
              Logout
            </Link>
          </li>
        </ul>
      </nav>
  )
}

Navbar.propTypes = {
  closed: PropTypes.bool.isRequired,
  closeClick: PropTypes.func.isRequired,
};
