import {useEffect} from "react";

export const addServerErrors = (setError, errors) => {
  if (errors) {
    return Object.keys(errors).forEach((key) => {
      try {
        setError(key, {
          type: "server",
          message: errors[key].join(" ")
        })
      } catch (e) {
        console.error(`error setting error with ${key}`)
      }
    });
  }
}

export const useServerErrors = (setError, errors) => useEffect(() => {
  addServerErrors(setError, errors)
}, [setError, errors])

export const handleServerSubmit = (formMethods, onSubmit) => {
  const {clearErrors, handleSubmit} = formMethods
  return e => {
    clearErrors('non_field_errors')
    clearErrors('payment_method')
    return handleSubmit(onSubmit)(e)
  }
}
