import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {performPasswordChange, selectComplete, selectErrors, setComplete} from "./changePasswordSlice";
import {useLocation} from "react-router";
import {FormProvider, useForm} from "react-hook-form";
import {handleServerSubmit, useServerErrors} from "../../form/formErrors";
import {ErrorMessage} from "@hookform/error-message";
import './ChangePassword.scss'
import {Field} from "../../form/Field";

export function ChangePassword() {
  const dispatch = useDispatch()
  const complete = useSelector(selectComplete)
  const formMethods = useForm()
  const {errors, setError} = formMethods
  const apiErrors = useSelector(selectErrors)

  useServerErrors(setError, apiErrors)

  const onSubmit = values => {
    dispatch(performPasswordChange(values.old_password, values.new_password1, values.new_password2))
  }

  let location = useLocation();
  React.useEffect(() => {
    dispatch(setComplete(false))
  }, [dispatch, location]);

  return (
      <FormProvider {...formMethods}>
        <form className={"form ChangePassword"} onSubmit={handleServerSubmit(formMethods, onSubmit)}>
          <h2>Change password</h2>
          {complete ? (
              <p>Password change successful!</p>
          ) : (
              <React.Fragment>
                <ErrorMessage className="error-message" name="non_field_errors" as="div" errors={errors}/>
                <Field
                    type={"password"}
                    autoComplete={"current-password"}
                    label="Current password"
                    name={"old_password"}
                    required={true}
                />
                <Field
                    type={"password"}
                    autoComplete={"new-password"}
                    label="New password"
                    name={"new_password1"}
                    helpText={"Passwords must be at least 8 characters long, cannot include numbers only, " +
                    "and cannot be too similar to your email address or other commonly used passwords."}
                    required={true}
                />
                <Field
                    type={"password"}
                    autoComplete={"new-password"}
                    label="Confirm password"
                    name={"new_password2"}
                    required={true}
                />
                <div className={"field"}>
                  <button>Change password</button>
                </div>
              </React.Fragment>
          )}
        </form>
      </FormProvider>
  );
}
