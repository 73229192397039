import React from "react";
import {CreateUpdateBusiness} from "../business/CreateUpdateBusiness";
import "./Onboarding.scss"
import {SwurfSpinner} from "../../components/SwurfSpinner";
import {useSelector} from "react-redux";
import {selectBusinessUpdating} from "../business/businessesSlice";
import {Redirect} from "react-router-dom";

export const Onboarding = () => {
  const loading = useSelector(selectBusinessUpdating)

  return (
      <div className={"Onboarding contentArea"}>
        <Redirect from={"*"} to={"/"} />
        <SwurfSpinner loading={loading} />
        <div className={"content"}>
          <CreateUpdateBusiness />
        </div>
      </div>
  )
}
