import {createSlice} from '@reduxjs/toolkit'
import {client} from "../../api/client";

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem("auth/token"),
    user: undefined,
    isLoading: false,
    errors: {
      login: null,
      registration: null,
      profileUpdate: null,
      passwordReset: null,
    },
  },
  reducers: {
    setLoading: state => {
      state.isLoading = true
    },
    setToken: (state, action) => {
      console.log(`setting auth token`)
      let token = action.payload;
      state.token = token
      localStorage.setItem("auth/token", token)
    },
    setUser: (state, action) => {
      let user = action.payload;
      console.log(`updating user ${user.email}`)
      state.user = user
      state.isLoading = false
    },
    logout: state => {
      console.log("logging out")
      state.user = null
      state.token = null
      localStorage.clear()
    },
    loginError: (state, action) => {
      state.isLoading = false
      state.errors.login = action.payload
    },
    registrationError: (state, action) => {
      state.isLoading = false
      state.errors.registration = action.payload
    },
    passwordResetError: (state, action) => {
      state.isLoading = false
      state.errors.passwordReset = action.payload
    },
    profileUpdateError: (state, action) => {
      state.isLoading = false
      state.errors.profileUpdate = action.payload
    },
  }
})

const { setLoading, setToken, setUser, loginError, registrationError, passwordResetError, profileUpdateError, logout} = authSlice.actions

export const performLogin = (email, password) => async dispatch => {
  console.log(`performing login for ${email}`)
  dispatch(setLoading())
  try {
    let response = await client.post('rest-auth/login/', {email, password})
    dispatch(loginError(null))
    dispatch(setToken(response.key))
    dispatch(fetchUser())
  } catch (e) {
    dispatch(logout())
    dispatch(loginError(e.data))
  }
}

export const performLogout = () => async dispatch => {
  dispatch(logout())
  client.post('rest-auth/logout/')
}

export const performRegistration = (name, email, password1, password2) => async dispatch => {
  console.log(`performing registation for ${email}`)
  dispatch(setLoading())
  try {
    let response = await client.post('rest-auth/registration/', {
      user_type: 'HOST',
      name,
      email,
      password1,
      password2,
    })
    dispatch(registrationError(null))
    dispatch(setToken(response.key))
    dispatch(fetchUser())
  } catch (e) {
    dispatch(logout())
    dispatch(registrationError(e.data))
  }
}

export const fetchUser = () => async (dispatch, getState) => {
  console.log("fetching user")
  dispatch(setLoading())
  try {
    let user = await client.get('rest-auth/user/', selectToken(getState()))
    console.log(`current user ${user.email}`)
    dispatch(setUser(user))
  } catch (e) {
    dispatch(logout())
  }
};

export const performProfileUpdate = (name) => async (dispatch, getState) => {
  console.log("fetching user")
  dispatch(setLoading())
  try {
    const user = await client.put('rest-auth/user/', {name}, selectToken(getState()))
    dispatch(setUser(user))
  } catch (e) {
    dispatch(profileUpdateError(e.data))
  }
}

export const performPasswordReset = (email) => async dispatch => {
  console.log(`performing password reset for ${email}`)
  dispatch(setLoading())
  try {
    let response = await client.post('rest-auth/password/reset/', {email})
    dispatch(passwordResetError(null))
    return response
  } catch (e) {
    dispatch(passwordResetError(e.data))
  }
}

export const selectToken = state => state.auth.token

export const selectUser = state => state.auth.user

export const selectAuthenticated = state => {
  let user = selectUser(state)
  if (user === undefined)
    return undefined;
  return user !== null;
}

export const selectIsLoading = state => state.auth.isLoading
export const selectLoginErrors = state => state.auth.errors.login
export const selectRegistrationErrors = state => state.auth.errors.registration
export const selectProfileUpdateErrors = state => state.auth.errors.profileUpdate
export const selectPasswordResetErrors = state => state.auth.errors.passwordReset

export default authSlice.reducer
