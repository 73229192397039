import "./PayPal.scss"
import {hostById, useHosts} from "../host/hostSlice";
import {useParams} from "react-router";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {SwurfSpinner} from "../../components/SwurfSpinner";
import React, {useEffect, useState} from "react";
import {Plan} from "./Plan";

export const PayPal = () => {
  const {hosts} = useHosts()
  const {id} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [host, setHost] = useState(null)

  useEffect(() => {
    if (hosts && id)
      setHost(hostById(hosts, id))
  }, [hosts, id, setHost])

  useEffect(() => {
    if (host !== null && (host === undefined || host.payment_method !== "PAYPAL" || (host.subscription && host.subscription.status !== 'APPROVAL_PENDING')))
      history.push('/')
  }, [dispatch, history, host])
  const [clicked, setClicked] = useState(false)
  const loading = !host || clicked

  const onCheckout = async () => {
    const subscription = host.subscription
    setClicked(true)
    window.location.href = subscription.links[subscription.links.findIndex(l => l.rel === "approve")].href
  }

  return (
      <div className={"PayPal contentArea form"}>
        <SwurfSpinner loading={loading} />
        {!loading && (
            <div className={"content"}>
              <div className={"planContainer"}>
                <Plan plan={host.subscription.plan} />
                <button className={"button"} onClick={onCheckout}>Checkout</button>
              </div>
            </div>
        )}
      </div>
  )
}
