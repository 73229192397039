import React from "react";
import "./Profile.scss"
import {ChangePassword} from "../changePassword/ChangePassword";
import {SwurfSpinner} from "../../components/SwurfSpinner";
import {useSelector} from "react-redux";
import {selectIsLoading as selectPasswordLoading} from "../changePassword/changePasswordSlice";
import {selectIsLoading as selectProfileLoading} from "../auth/authSlice";
import {EditProfile} from "./EditProfile";

export const Profile = () => {
  const loading = useSelector(selectPasswordLoading) | useSelector(selectProfileLoading)

  return (
      <div className={"Profile contentArea"}>
        <SwurfSpinner loading={loading} />
        <div className={"content"}>
          <EditProfile />
          <ChangePassword />
        </div>
      </div>
  )
}
