import React from 'react';
import {Login} from "./Login";
import {Register} from "./Register";
import "./LoginRegister.scss"
import {useSelector} from "react-redux";
import {selectIsLoading} from "./authSlice";
import {Link, Redirect, Route, Switch, useLocation} from "react-router-dom";
import wordmark from "../../assets/WordmarkViolet.svg";
import {SwurfSpinner} from "../../components/SwurfSpinner";
import {PasswordReset} from "./PasswordReset";


export function LoginRegister() {
  const loading = useSelector(selectIsLoading)
  const location = useLocation()

  return (
      <div className={"LoginRegister"}>
        <div className={"image"}>
        </div>
        <a className={"backToSwurfCo"} href={"https://swurf.co/"}>Back to swurf.co</a>
        <div className={"form"}>
          <SwurfSpinner loading={loading} />
          <div className={"header"}>
            <div className={"dummy"} />
            <div className={"logo"}><h1><Link to="/"><img src={wordmark} alt={"SWURF"} /></Link></h1></div>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a className={"backToSwurfCo"} href={"https://swurf.co/"} />
          </div>
          <Switch>
            <Route path={"/login/"}>
              <Login />
              <div className={"footer"}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                By continuing you accept our <a target={"_blank"} href={"https://swurf.co/terms-and-conditions/"}>Terms of Use</a> and <a target={"_blank"} href={"https://swurf.co/privacy-policy/"}>Privacy Policy</a>.
              </div>
            </Route>
            <Route path={"/register/"}>
              <Register />
              <div className={"footer"}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                By signing up you accept our <a target={"_blank"} href={"https://swurf.co/terms-and-conditions/"}>Terms of Use</a> and <a target={"_blank"} href={"https://swurf.co/privacy-policy/"}>Privacy Policy</a>.
              </div>
            </Route>

            <Route path={"/password-reset/"}>
              <PasswordReset />
              <div className={"footer"}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                By continuing you accept our <a target={"_blank"} href={"https://swurf.co/terms-and-conditions/"}>Terms of Use</a> and <a target={"_blank"} href={"https://swurf.co/privacy-policy/"}>Privacy Policy</a>.
              </div>
            </Route>
            <Redirect to={`/login/?next=${location.pathname}`} />

          </Switch>
        </div>
      </div>
  );
}
