import {ErrorMessage} from "@hookform/error-message";
import React from "react";
import "./Field.scss"
import {useFormContext} from "react-hook-form";

export const TextArea = ({label, name, helpText, required=false, placeholder=null, maxLength=null, registerOptions={}}) => {
  const {errors, register} = useFormContext()

  if (required && registerOptions.required === undefined)
    registerOptions.required = required === true ? "This field is required" : required
  if (maxLength && registerOptions.maxLength === undefined)
    registerOptions.maxLength = {
      value: maxLength,
      message: `Cannot exceed ${maxLength} characters`,
    }

  return (
      <div className={`field ${errors.name ? 'error' : ''} ${required ? 'required' : ''} TextArea field_${name}`}>
        <label>
          <span className={'fieldLabel'}>
            <span className={'labelText'}>{label}</span>
            {helpText && (
                <span className={"helpText"}>{helpText}</span>
            )}
          </span>
          <span className={'fieldInput'}>
            <textarea
              aria-label={label}
              name={name}
              rows={3}
              placeholder={placeholder}
              maxLength={maxLength}
              ref={register(registerOptions)}/>
          </span>
        </label>
        <ErrorMessage className="fieldError" name={name} as="div" errors={errors} />
      </div>
  )
}
