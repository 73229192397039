import React from "react";
import {Link} from "react-router-dom";
import {useBusiness} from "../business/businessSlice";
import "./Dashboard.scss"
import {HostList} from "../host/HostList";
import {useHosts} from "../host/hostSlice";
import {SwurfSpinner} from "../../components/SwurfSpinner";
import {useSelector} from "react-redux";
import {selectUser} from "../auth/authSlice";

export const Dashboard = () => {
  const user = useSelector(selectUser)
  const business = useBusiness()
  const {hasHost} = useHosts()
  const loading = business === undefined || hasHost === null
  const isAdmin = user.business_users.find(b => b.business === business?.id)?.is_admin

  return (
      <div className={`Dashboard contentArea ${hasHost ? "hasHost": "noHosts"}`}>
        <SwurfSpinner loading={loading} />
        <div className={"content"}>
          {!loading && (
              <div className={"dashboardContent"}>
                <div className={"dashboardHeader"}>
                  <div className={"businessTitle"}>
                    <h3>{business.name}</h3>
                    <div className={"businessSubtitle"}>
                      {business.city}, {business.country}
                      {isAdmin && (
                          <React.Fragment>
                            &nbsp;
                            <Link to={{pathname: `/business/edit/`, state: {next: '/'}}}>
                              Edit Profile
                            </Link>
                          </React.Fragment>
                      )}
                    </div>
                  </div>
                  {isAdmin && (
                    <Link to={`/host/create/`} className={"button createHost"} >
                      Create listing
                    </Link>
                  )}
                </div>

                {hasHost ? (
                    <React.Fragment>
                      <HostList />
                    </React.Fragment>
                ) : (
                    <div className={"createFirstHost"}>
                      <p>Create your first listing!</p>
                      <Link to={'/host/create/'} className={"button"}>
                          Create listing
                      </Link>
                    </div>
                )}
              </div>
          )}
        </div>
      </div>
  )
}
