import {createSlice} from "@reduxjs/toolkit";
import {client} from "../../api/client";
import {selectToken} from "../auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

export const paypalSlice = createSlice({
  name: 'paypal',
  initialState: {
    isLoading: false,
    plans: null,
  },
  reducers: {
    setLoading: state => {
      state.isLoading = true
    },
    setPlans: (state, action)  => {
      state.plans = action.payload
      state.isLoading = false
    },
  }
})

const { setLoading, setPlans, } = paypalSlice.actions

const fetchPlans = (promotionCode, abort) => async (dispatch, getState) => {
  const state = getState();
  dispatch(setLoading())
  let endpoint = "paypal/plans/";
  if (promotionCode)
    endpoint = `${endpoint}?promotion_code=${promotionCode}`
  try {
    let response = await client.get(endpoint, selectToken(state), {signal: abort.signal})
    console.log(`setting plans: ${response}`)
    dispatch(setPlans(response))
  } catch (e) {
    if (!abort.signal.aborted)
      throw e
  }
}

export const selectIsLoading = state => state.paypal.isLoading
export const selectPlans = state => state.paypal.plans

export const planForCovers = (plans, covers) => {
  let paypalPlan = null;
  plans?.forEach((p) => {
    const currentLowestCovers = paypalPlan?.max_number_of_covers || Infinity;
    const candidateCovers = p.max_number_of_covers || Infinity;
    const planFitsCovers = candidateCovers >= covers;
    const planBetterThanCurrent = candidateCovers <= currentLowestCovers;
    if (planFitsCovers && planBetterThanCurrent)
      paypalPlan = p
  })
  return paypalPlan;
}

export const usePayPalPlans = (promotionCode) => {
    const dispatch = useDispatch()
    const plans = useSelector(selectPlans)
    const isLoading = useSelector(selectIsLoading)

    useEffect(() => {
      let abort = new AbortController()
      dispatch(fetchPlans(promotionCode, abort))
      return () => {
        abort.abort()
      }
    }, [dispatch, promotionCode])

    return {plans, isLoading}
}

export default paypalSlice.reducer
