import React from 'react';
import './App.scss';
import {selectAuthenticated} from "../features/auth/authSlice";
import {useSelector} from "react-redux";
import {Main} from "../features/main/Main";
import {LoginRegister} from "../features/auth/LoginRegister";
import {useBusinesses} from "../features/business/businessesSlice";
import {SwurfSpinner} from "../components/SwurfSpinner";

function App() {
  const authenticated = useSelector(selectAuthenticated)
  const {businesses} = useBusinesses()

  if (authenticated === undefined)
    return <SwurfSpinner/>

  if (authenticated === true) {
    if (businesses === null)
      return <SwurfSpinner/>
    return <Main/>
  }

  return <LoginRegister />
}

export default App;
