import React from "react";
import {Route, Switch} from "react-router-dom";
import {Dashboard} from "./Dashboard";
import {Redirect} from "react-router";
import {EditBusiness} from "../business/EditBusiness";
import {CreateHost} from "../host/CreateHost";
import {EditHost} from "../host/EditHost";
import "./BusinessContent.scss"
import {PayPal} from "../paypal/PayPal";
import {PaymentSuccess} from "../paypal/PaymentSuccess";
import {PaymentError} from "../paypal/PaymentError";
import {PaymentCancelled} from "../paypal/PaymentCancelled";
import {ContentNavBar} from "./ContentNavBar";

export const BusinessContent = ({hamburgerClick}) => {
  return (
      <React.Fragment>
        <Switch>
          <Route path="/" exact>
            <ContentNavBar title={"Your listings"} hamburgerClick={hamburgerClick} />
            <Dashboard />
          </Route>
          <Route path={"/business/edit/"} exact>
            <ContentNavBar title={"Edit your company details"} hamburgerClick={hamburgerClick} />
            <EditBusiness />
          </Route>
          <Route path={"/host/create/"}>
            <ContentNavBar
                title={"Create your listing"}
                subtitle={"This is your opportunity to promote your space to a new community of remote workers."}
                hamburgerClick={hamburgerClick}
            />
            <CreateHost />
          </Route>
          <Route path={"/host/:id/edit/"}>
            <ContentNavBar title={"Edit your listing"} hamburgerClick={hamburgerClick} />
            <EditHost />
          </Route>
          <Route path={"/host/:id/payment/"} exact>
            <ContentNavBar title={"Payment"} hamburgerClick={hamburgerClick} />
            <PayPal />
          </Route>
          <Route path={"/host/:id/payment/success/"} exact>
            <ContentNavBar title={"Payment successful!"} hamburgerClick={hamburgerClick} />
            <PaymentSuccess />
          </Route>
          <Route path={"/host/:id/payment/error/"} exact>
            <ContentNavBar title={"Payment error!"} hamburgerClick={hamburgerClick}/>
            <PaymentError />
          </Route>
          <Route path={"/host/:id/payment/cancel/"} exact>
            <ContentNavBar title={"Payment cancelled!"} hamburgerClick={hamburgerClick} />
            <PaymentCancelled />
          </Route>
          <Redirect to={"/"} />
        </Switch>
      </React.Fragment>
  )
}
