import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {handleServerSubmit, useServerErrors} from "../../../form/formErrors";
import {ErrorMessage} from "@hookform/error-message";
import {INVOICE, PAYPAL, selectErrors, selectIsLoading, updateFormState} from "../hostSlice";
import "./Page8Payment.scss"
import {useHistory} from "react-router";
import {Field} from "../../../form/Field";
import {SwurfSpinner} from "../../../components/SwurfSpinner";
import {planForCovers, usePayPalPlans} from "../../paypal/paypalSlice";
import {Plan} from "../../paypal/Plan";

export function Page8Payment(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [plan, setPlan] = useState()
  const [invoicable, setInvoicable] = useState(false)

  useEffect(() => {
    document.getElementsByClassName('contentArea')[0].scroll({top: 0})
  }, [])

  const host = props.host

  const loading = useSelector(selectIsLoading)

  const formMethods = useForm({
    defaultValues: props.defaultValues,
  })
  const {errors, setError, watch} = formMethods
  const apiErrors = useSelector(selectErrors)
  useServerErrors(setError, apiErrors)

  const promotionCode = watch('promotion_code')

  const [submitAction, setSubmitAction] = useState()
  const onSubmit = async values => {
  
    await dispatch(updateFormState(host, {
      ...values,
      payment_method: submitAction,
      plan,
      promotionCode,
    }))
    if (submitAction) {
      history.push("../checkout/")
    } else {
      history.push("../page8/")
    }
  }
  const {plans, isLoading: plansLoading} = usePayPalPlans(promotionCode)

  const covers = parseInt(watch('number_of_covers'))
  useEffect(() => {
    if (covers > 0) {
      const newPlan = planForCovers(plans, covers);
      setPlan(newPlan)
      if (newPlan) {
        let lastCycle = newPlan.billing_cycles[newPlan.billing_cycles.length - 1];
        setInvoicable(lastCycle.interval_unit === "YEAR")
      }
    } else {
      setPlan(null)
    }
  }, [setPlan, covers, plans])

  return (
      <FormProvider {...formMethods}>
        <form className={"form Page8Payment"} onSubmit={handleServerSubmit(formMethods, onSubmit)}>
          <ErrorMessage className="error-message" name="non_field_errors" as="div" errors={errors}/>
          <SwurfSpinner loading={loading} />
          <div className={"formSection"}>
            <fieldset className={"coversFieldSet"}>
              <h3 className={"withDescription"}>How big is your space?</h3>
              <div className={"pageDescription"}>
                Subscription is charged for each space listing, and calculated by size.<br />
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                For more details please head back to <a target={"_blank"} href={"https://swurf.co/pricing-matrix/"}>SWURF.co</a> (opens in a new tab) or you can always contact us for support.
              </div>
              <Field
                  type={"number"}
                  name={"number_of_covers"}
                  label={"Number of covers"}
                  required={true}/>
              <Field
                  name={"promotion_code"}
                  label={"Promotion code"} />
            </fieldset>
            <div className={"planContainer"}>
              {plansLoading || plans === null ? (
                <SwurfSpinner />
              ) : (
                plans.length ? (
                  plan ? (
                    <React.Fragment>
                      <Plan plan={plan} promotionCode={promotionCode} />
                      <div className={"field"}>
                        <button onClick={() => setSubmitAction(PAYPAL)}>Pay by PayPal</button>
                        {invoicable && (
                          <button onClick={() => setSubmitAction(INVOICE)}>Pay by Invoice</button>
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className={"noPlan"}>
                      Enter number of covers to calculate pricing
                    </div>
                  )
                ) : (
                  <div className={"invalidPromotionCode"}>
                    Invalid promotion code!
                  </div>
                )
              )}
            </div>
          </div>

          <div className={"field pageControls"}>
            <div>
              <button onClick={() => setSubmitAction(undefined)}>back</button>
            </div>
            {props.onCancel && (
              <div>
                <button className={"cancel"} onClick={(e) => {
                  props.onCancel()
                  e.preventDefault();
                }}>
                  cancel
                </button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
  );
}
