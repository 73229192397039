import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {updateFormState} from "../hostSlice";
import {Field} from "../../../form/Field";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import "./Page2Location.scss"
import {SwurfSpinner} from "../../../components/SwurfSpinner";
import {GooglePlaces} from "../../../form/GooglePlaces";
import {lavendar, violet} from "../../../constants/colors";
import {swurfMapId} from "../../../constants/constants";
import {useHistory} from "react-router-dom";

const libraries = ['places'];

export function Page2Location(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    document.getElementsByClassName('contentArea')[0].scroll({top: 0})
  }, [])

  const [center, setCenter] = useState({
    lat: 55.432048,
    lng: -3.013923,
  })
  const [zoom, setZoom] = useState(5)

  const host = props.host

  useEffect(() => {
    if (host) {
      setCenter({
        lat: host.latitude,
        lng: host.longitude,
      })
      setZoom(15)
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          setZoom(13)
        })
      }
    }
  }, [host])

  const {isLoaded: isMapScriptLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries,
    mapIds: [swurfMapId],
  })

  const [map, setMap] = useState(null)
  const onMapLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onMapUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const formMethods = useForm({
    defaultValues: props.defaultValues,
  })
  const {errors, setValue, watch, handleSubmit, register} = formMethods
  const [submitAction, setSubmitAction] = useState()
  const onSubmit = values => {
    dispatch(updateFormState(host, values))
    if (submitAction === "back")
      history.push("../")
    else
      history.push("../page3/")
  }

  const [editAddress, setEditAddress] = useState(!host && !props.defaultValues.street_address)
  const editAddressToggle = useCallback((e) => {
    e?.preventDefault()
    setEditAddress(!editAddress)
  }, [editAddress, setEditAddress])

  const [addressManual, setAddressManual] = useState(false)
  const manualAddressToggle = useCallback((e) => {
    e?.preventDefault()
    setAddressManual(!addressManual)
  }, [addressManual, setAddressManual])

  const searchChanged = useCallback((e) => {
    if (!e) return

    const place = e.value
    const placeId = place.place_id
    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails({
      placeId: placeId,
      fields: ['name', 'address_component', 'geometry'],
    }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        setCenter(location)
        setZoom(17)
        var streetAddress = "", city, region, postcode, country
        place.address_components.forEach((comp) => {
          const types = comp.types;
          if (types.includes('street_number') || types.includes('route')) {
            streetAddress = `${streetAddress} ${comp.long_name}`
          } else if (types.includes('postal_code')) {
            postcode = comp.long_name
          } else if (types.includes('postal_town')) {
            city = comp.long_name
          } else if (types.includes('administrative_area_level_1')) {
            region = comp.long_name
          } else if (types.includes('country')) {
            country = comp.long_name
          }
        })
        if (!region) {
          place.address_components.forEach((comp) => {
            const types = comp.types;
            if (types.includes('administrative_area_level_2')) {
              region = comp.long_name;
            }
          })
        }
        setValue('street_address', streetAddress, {shouldDirty: true, shouldValidate: true})
        setValue('city', city, {shouldDirty: true, shouldValidate: true})
        setValue('region', region, {shouldDirty: true, shouldValidate: true})
        setValue('country', country, {shouldDirty: true, shouldValidate: true})
        setValue('postcode', postcode, {shouldDirty: true, shouldValidate: true})
        setValue('latitude', location.lat, {shouldDirty: true, shouldValidate: true})
        setValue('longitude', location.lng, {shouldDirty: true, shouldValidate: true})
        setValue('place_id', placeId)
        if (streetAddress && city && country && postcode) {
          editAddressToggle()
        } else {
          manualAddressToggle()
        }
      }
    })
  }, [editAddressToggle, manualAddressToggle, map, setValue])

  const marker = (watch('latitude') && watch('longitude')) ? {
    lat: parseFloat(watch('latitude')),
    lng: parseFloat(watch('longitude')),
  } : null

  const addressErrors = errors.street_address || errors.city || errors.country || errors.postcode
  const locationErrors = errors.latitude || errors.longitude
  const addressSet = !!watch('street_address')

  return (
      <FormProvider {...formMethods}>
        <form className={`form Page2Location ${addressSet && "addressSet"} ${editAddress && "editAddress"}`} onSubmit={handleSubmit(onSubmit)}>
          <div className={"formSection addressSection"}>
            <h3>Tell us where you are</h3>
            <div>
              {isMapScriptLoaded ? (
                  <React.Fragment>
                    <fieldset className={`addressFieldSet ${addressManual && editAddress ? "addressManual" : ""}`}>
                      <input type={"hidden"} name={"place_id"} ref={register()} />
                      {editAddress ? (
                          <div className={"field search"}>
                            <GooglePlaces
                                withSessionToken={true}
                                autocompletionRequest={{
                                  location: marker || center,
                                  radius: 1000,
                                }}
                                onChange={searchChanged}
                                selectProps={{
                                  placeholder: "Search...",
                                  styles: {
                                    control: () => ({
                                      border: `1px solid ${violet}`,
                                      borderRadius: 5,
                                      display: 'flex',
                                    }),
                                    dropdownIndicator: (provided) => {
                                      return {...provided, color: violet}
                                    },
                                    valueContainer: (provided) => {
                                      return {...provided, padding: 15, fontSize: 20}
                                    },
                                    menu: (provided) => {
                                      return {...provided, backgroundColor: lavendar, color: violet}
                                    },
                                  }
                                }}
                            />
                            <div className={"manualAddressToggle"}>
                              Search for an address above or
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              &nbsp;<a href={"#"} onClick={manualAddressToggle}>enter an address manually</a>
                            </div>
                            {addressErrors && (
                                <div className="fieldError">Address is required</div>
                            )}
                            {addressSet && (
                                <div className={"field"}>
                                  <button onClick={editAddressToggle}>Cancel</button>
                                </div>
                            )}
                          </div>
                      ) : (
                          <React.Fragment>
                            <div className={"field address"}>
                              <div>{watch('street_address')}</div>
                              <div>{watch('city')}</div>
                              <div>{watch('region')}</div>
                              <div>{watch('country')}</div>
                              <div>{watch('postcode')}</div>
                            </div>
                            <div className={"field"}>
                              <div className={"editAddressToggle"}>
                                <button onClick={editAddressToggle}>Change address</button>
                              </div>
                            </div>
                          </React.Fragment>
                      )}
                      <div className={"manual"}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <div className={"manualAddressToggle"}>Enter an address below or <a href={"#"}
                                                                                            onClick={manualAddressToggle}>search
                          for an address</a></div>
                        <Field
                            label="Street address"
                            name={"street_address"}
                            required={true}
                        />

                        <Field
                            label="Town or city"
                            name={"city"}
                            required={true}
                        />

                        <Field
                            label="Region"
                            name={"region"}
                        />

                        <Field
                            label="Postcode"
                            name={"postcode"}
                            required={true}
                        />

                        <Field
                            label="Country"
                            name={"country"}
                            required={true}
                        />

                        <Field
                            label="latitude"
                            name={"latitude"}
                            required={true}
                        />

                        <Field
                            label="longitude"
                            name={"longitude"}
                            required={true}
                        />
                        {addressSet && (
                            <div className={"field"}>
                              <button onClick={editAddressToggle}>Cancel</button>
                            </div>
                        )}
                      </div>
                    </fieldset>
                    <div className={"map"}>
                      {(addressManual || !editAddress) && (
                          <div className={"changeLocationHint"}>
                            {marker ? (
                                "Not exactly right? Click on map to let swurfers know exactly where you are."
                            ) : (
                                "Not exactly right? Click on map to let swurfers know exactly where you are."
                            )}
                          </div>
                      )}
                      {locationErrors && (
                          <div className="fieldError">Map location is required</div>
                      )}
                      <div className={"mapContainer"}>
                        <GoogleMap
                            mapContainerStyle={{
                              width: '100%',
                              height: '100%',
                              minHeight: "50vh"
                            }}
                            options={{
                              mapId: swurfMapId,
                              disableDefaultUI: true,
                              zoomControl: true,
                            }}
                            zoom={zoom}
                            center={marker || center}
                            onLoad={onMapLoad}
                            onUnmount={onMapUnmount}
                            onClick={(e) => {
                              setValue('latitude', e.latLng.lat())
                              setValue('longitude', e.latLng.lng())
                            }}>
                          {marker && (
                              // TODO marker icon
                              <Marker position={marker}/>
                          )}
                        </GoogleMap>
                        {editAddress && !addressManual && (
                            <div className={"mapOverlayContainer"}>
                              <div className={"mapOverlay"}>Search for an address first</div>
                            </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
              ) : (
                  <SwurfSpinner backgroundColor={"transparent"}/>
              )}
            </div>
          </div>

          <div className={"field pageControls"}>
            <div>
              <button onClick={() => setSubmitAction("back")}>back</button>
              <button onClick={() => setSubmitAction("next")}>next</button>
            </div>
            {props.onCancel && (
              <div>
                <button className={"cancel"} onClick={(e) => {
                  props.onCancel()
                  e.preventDefault();
                }}>
                  cancel
                </button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
  );
}
