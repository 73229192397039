import "./HostListItem.scss"
import {Link} from "react-router-dom";

const PAYMENT_INCOMPLETE = 'PAYMENT_INCOMPLETE'
const PAYMENT_ERROR = 'PAYMENT_ERROR'

const STATES = {
  'NEW': 'Pending approval',
  'APPROVED': 'Live',
  [PAYMENT_INCOMPLETE]: 'Payment incomplete!',
  [PAYMENT_ERROR]: 'Payment error!',
}


export const HostListItem = (props) => {
  const host = props.host
  var state
  if (host.payment_method === 'PAYPAL') {
    const status = host.subscription?.status;
    if (!status || status === 'APPROVAL_PENDING') {
      state = PAYMENT_INCOMPLETE
    } else if (status === 'ACTIVE') {
      state = host.approval_state
    } else {
      state = PAYMENT_ERROR
    }
  } else {
    state = host.approval_state
  }

  const initials = host.name.trim().split(/\s+/).slice(0, 2).map(w => w[0]).join("").toUpperCase()

  let renderedStatus
  switch (state) {
    case PAYMENT_INCOMPLETE:
      renderedStatus = (
        <Link className={`status ${state} button`} to={`/host/${host.id}/payment/`}>{STATES[state]}</Link>
      )
      break;
    default:
      renderedStatus = (
        <div className={`status ${state}`}>{STATES[state]}</div>
      )
  }

  return (
      <div className={"HostListItem"}>
        {host.images?.length ? (
          <div className={"image"} style={{backgroundImage: `url("${host.images[0].image}")`}} />
        ) : (
          <div className={"image noImage"}>{initials}</div>
        )}
        <div className={"details"}>
          <div className={"header"}>
            <div className={"headerLeft"}>
              <div className={"name"}>
                {host.name}
              </div>
              {renderedStatus}
            </div>
            <Link className={"editButton button"} to={`host/${host.id}/edit/`}>Edit listing</Link>
          </div>
          <div className={"address"}>
            {[
                host.street_address,
                host.city,
                host.postcode,
            ].filter(v => v).join(", ")}
          </div>
          {host.promotions?.length > 0 && (
            <div className={"promotions"}>
              {host.promotions?.map((promotion) => (
                  <div key={promotion.id} className={"promotion"}>
                    <span className={"title"}>Active promotion</span>
                    <span className={"code"}>{promotion.code}</span>
                    <span className={"description"}>{promotion.description}</span>
                  </div>
              ))}
            </div>
          )}
        </div>
      </div>
  )
}
