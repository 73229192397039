import React, {useState} from "react";
import {Route, Switch} from "react-router-dom";
import {BusinessContent} from "./BusinessContent";
import {Onboarding} from "../onboarding/Onboarding";
import {useBusinesses} from "../business/businessesSlice";
import {Navbar} from "./Navbar";
import "./Main.scss"
import {Profile} from "../profile/Profile";
import {useBusiness} from "../business/businessSlice";
import {SwurfSpinner} from "../../components/SwurfSpinner";
import {ContentNavBar} from "./ContentNavBar";

export const Main = () => {
  const {hasBusiness} = useBusinesses()
  const business = useBusiness()
  const [menuClosed, setMenuClosed] = useState(true)

  const hamburgerClick = () => {
    setMenuClosed(false)
  }

  const menuItemClick = () => {
    setMenuClosed(true)
  }

  const closeClick = () => {
    setMenuClosed(true)
  }

  return (
      <div className={"Main"}>
        <Navbar closed={menuClosed} itemClick={menuItemClick} closeClick={closeClick} />
        <div className={"contentContainer"}>
          <Switch>

            <Route path="/account/" exact>
              <ContentNavBar title={"Your account"} hamburgerClick={hamburgerClick} />
              <Profile />
            </Route>

            <Route path="/">
              {hasBusiness ? (
                  business ? (
                    <BusinessContent hamburgerClick={hamburgerClick} />
                  ) : (
                      <SwurfSpinner />
                  )
              ) : (
                <React.Fragment>
                  <ContentNavBar title={"Get started!"} hamburgerClick={hamburgerClick} />
                  <Onboarding/>
                </React.Fragment>
              )}
            </Route>
          </Switch>
        </div>
      </div>
  )
}
