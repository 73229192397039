import {createSlice} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {selectBusinesses, useBusinesses} from "./businessesSlice";

export const businessSlice = createSlice({
  name: 'business',
  initialState: {
    business: null,
  },
  reducers: {
    setBusiness: (state, action) => {
      state.business = action.payload
    },
  }
})

const { setBusiness } = businessSlice.actions

export const selectBusiness = state => selectBusinesses(state)[state.business.business]

export const useBusiness = () => {
  const dispatch = useDispatch()
  const {businesses, hasBusiness} = useBusinesses()
  const business = useSelector(selectBusiness)

  useEffect(() => {
    if (hasBusiness && business === undefined) {
      const business = businesses[0];
      console.log(`setting business ${business?.name}`)
      const businessId = businesses.findIndex(b => business.id === b.id)
      dispatch(setBusiness(businessId))
    }
  }, [dispatch, hasBusiness, business, businesses])
  return business
}

export default businessSlice.reducer
