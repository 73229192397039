import React from "react";
import {performLogin, selectLoginErrors} from "./authSlice";
import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useForm} from "react-hook-form";
import {ErrorMessage} from '@hookform/error-message';
import {handleServerSubmit, useServerErrors} from "../../form/formErrors";
import {Field} from "../../form/Field";
import {Link} from "react-router-dom";
import {useQuery} from "../../util/query";

export function Login() {
  const dispatch = useDispatch()
  const formMethods = useForm()
  const {errors, setError} = formMethods
  const apiErrors = useSelector(selectLoginErrors)
  useServerErrors(setError, apiErrors)

  const onSubmit = values => {
    dispatch(performLogin(values.email, values.password))
  }

  const next = useQuery().get('next') || '/'

  return (
      <FormProvider {...formMethods}>
        <form className={"Login"} onSubmit={handleServerSubmit(formMethods, onSubmit)}>
          <h2>Welcome Back!</h2>
          <div className={"authNav"}>New to Swurf? <Link to={`/register/?next=${next}`}>Create an Account</Link></div>
          <ErrorMessage className="error-message" name="non_field_errors" as="div" errors={errors}/>
          <Field
              type={"email"}
              label="Email"
              name={"email"}
              required={true}
          />
          <Field
              type={"password"}
              autoComplete={"current-password"}
              label="Password"
              name={"password"}
              required={true}
          />
          <div className={"field"}>
            <button>Login</button>
          </div>
          <div className={"authNav"}><Link to={`/password-reset/?next=${next}`}>Forgotten your password?</Link></div>
        </form>
      </FormProvider>
  );
}
