import React from "react";
import "./Plan.scss"

export const Plan = (props) => {
  const plan = props.plan
  const promotionCode = props.promotionCode

  return (
      <div className={"Plan"}>
        <h3>{plan.description}</h3>
        {plan.max_number_of_covers ? (
            <p>For spaces of up to {plan.max_number_of_covers} covers</p>
        ) : (
            <p>For spaces of any size</p>
        )}
        {plan.billing_cycles.map((cycle) => {
          const plurality = cycle.interval_count > 1 ? "plural" : "singular";
          const intervalCount = cycle.interval_count || cycle.frequency.interval_count;
          const intervalUnit = cycle.interval_unit || cycle.frequency.interval_unit;
          const intervalName = ({
            DAY: {plural: "days", singular: "day"},
            WEEK: {plural: "weeks", singular: "week"},
            MONTH: {plural: "months", singular: "month"},
            YEAR: {plural: "years", singular: "year"},
          }[intervalUnit] || {})[plurality] || "unknown";

          if (cycle.tenure_type === "TRIAL")
            return (
                <div key={cycle.sequence} className={"cycle trial"}>{intervalCount * cycle.total_cycles} {intervalName} free trial</div>
            )
          return (
            <div key={cycle.sequence} className={"cycle"}>&pound;{cycle.value || cycle.pricing_scheme.fixed_price.value} every {plurality === "plural" && `${intervalCount} `}{intervalName}</div>
          )
        })}
        {promotionCode && <p>Promotion code {promotionCode} applied</p>}
      </div>
  )
}
