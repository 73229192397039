import React from "react";
import "./Field.scss"

export const FauxField = ({label, name, helpText, required=false, children=null}) => {
  return (
      <div className={`field ${required ? 'required' : ''} field_${name}`}>
        <label>
          <span className={'fieldLabel'}>
            <span className={'labelText'}>{label}</span>
            {helpText && (
                <span className={"helpText"}>{helpText}</span>
            )}
          </span>
          <span className={'fieldInput'}>
            {children}
          </span>
        </label>
      </div>
  )
}
