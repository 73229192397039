import React from 'react';
import {performRegistration, selectRegistrationErrors} from "./authSlice";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {handleServerSubmit, useServerErrors} from "../../form/formErrors";
import {ErrorMessage} from "@hookform/error-message";
import {Field} from "../../form/Field";
import {Link} from "react-router-dom";
import {useQuery} from "../../util/query";

export function Register() {
  const dispatch = useDispatch()
  const formMethods = useForm()
  const {errors, setError} = formMethods
  const apiErrors = useSelector(selectRegistrationErrors)
  useServerErrors(setError, apiErrors)

  const onSubmit = values => {
    dispatch(performRegistration(values.name, values.email, values.password1, values.password2))
  }

  const next = useQuery().get('next') || '/'

  return (
      <FormProvider {...formMethods}>
        <form className={"Register"} onSubmit={handleServerSubmit(formMethods, onSubmit)}>
          <h2>Welcome to a<br/>New Community</h2>
          <div className={"authNav"}>Already registered? <Link to={`/login/?next=${next}`}>Login</Link></div>
          <ErrorMessage className="error-message" name="non_field_errors" as="div" errors={errors}/>
          <Field
              autoComplete={"name"}
              label="Your name"
              name={"name"}
              required={true}
          />
          <Field
              type={"email"}
              label="Email"
              name={"email"}
              required={true}
          />
          <Field
              type={"password"}
              autoComplete={"new-password"}
              label="Password"
              name={"password1"}
              helpText={"Passwords must be at least 8 characters long, cannot be entierly numeric " +
              "and cannot be too similar to your email address or other commonly used passwords."}
              required={true}
          />
          <Field
              type={"password"}
              autoComplete={"new-password"}
              label="Confirm password"
              name={"password2"}
              required={true}
          />
          <div className={"field"}>
            <button>Register</button>
          </div>
        </form>
      </FormProvider>
  );
}
