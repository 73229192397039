import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import changePasswordReducer from "../features/changePassword/changePasswordSlice";
import businessesReducer from "../features/business/businessesSlice";
import businessReducer from "../features/business/businessSlice";
import hostReducer from "../features/host/hostSlice";
import paypalReducer from "../features/paypal/paypalSlice";
import {categoriesReducer, facilitiesReducer, stringsReducer, typesOfWorkReducer} from "../features/data/slices";

const combinedReducer = combineReducers({
  auth: authReducer,
  changePassword: changePasswordReducer,
  businesses: businessesReducer,
  business: businessReducer,
  host: hostReducer,
  categories: categoriesReducer,
  typesOfWork: typesOfWorkReducer,
  facilities: facilitiesReducer,
  strings: stringsReducer,
  paypal: paypalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    console.log("clearing state")
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
