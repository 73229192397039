import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { updateFormState } from "../hostSlice";
import { useBusiness } from "../../business/businessSlice";
import { Field } from "../../../form/Field";
import { TextArea } from "../../../form/TextArea";
import "./Page1Initial.scss";
import { SwurfSpinner } from "../../../components/SwurfSpinner";
import { client } from "../../../api/client";
import { selectToken } from "../../auth/authSlice";
import { useHistory } from "react-router";
import Modal from "react-modal";

export function Page1Initial(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const business = useBusiness();
  const host = props.host;

  const [imageUploadError, setImageUploadError] = useState(false);
  const [imagesArr, setImagesArray] = useState(Array(5).fill(null));
  // set by default 5 box of images is shown on the screen

  useEffect(() => {
    document.getElementsByClassName("contentArea")[0].scroll({ top: 0 });
  }, []);

  useEffect(() => {
    if (props.defaultValues.images?.length) {
      //set upcoming images from api into our local state
      setImagesArray((prevImages) => {
        const newImages = props.defaultValues.images.slice(0, 5);
        return newImages.concat(prevImages.slice(newImages.length));
      });
    }
  }, [props]);

  const formMethods = useForm({
    defaultValues: props.defaultValues,
  });

  const { handleSubmit, watch } = formMethods;

  const onSubmit = async (values) => {
    let data = { ...values };
    // getting all data from form and set our updated code into form state
    if (imagesArr?.length) {
      data.images = imagesArr;
    } else {
      data.images = [];
    }
    props.defaultValues.images = data.images;
    props.defaultValues.description = data.description;
    props.defaultValues.name = data.name;
    //for updating all form data
    await dispatch(updateFormState(host, props.defaultValues));
    history.push("page2/");
  };

  const token = useSelector(selectToken);
  // image data sent to api and set response into our forms state
  const onImageChange = async (e, index) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const updatedImagesArr = [...imagesArr];
      updatedImagesArr[index] = { loading: true }; //set loader to true when uploading images

      setImagesArray(updatedImagesArr);
      const formData = new FormData();
      formData.append("image", files[0]);
      try {
        // this api is for uploading images
        const response = await client.post(
          `hosts/businesses/${business.id}/images/`,
          formData,
          token
        );
        //set images into our local state
        imagesArr[index] = response;
        setImagesArray(imagesArr);
      } catch (e) {
        updatedImagesArr[index] = { loading: false }; // Set loading to false on error
        setImagesArray(updatedImagesArr);
        setImageUploadError(true);
      }
    }
  };
  // on removing images remove from our local state
  const onRemoveImage = (index) => {
    const updatedImagesArr = [...imagesArr];
    updatedImagesArr[index] = null;
    setImagesArray(updatedImagesArr);
  };
  // to close image error popup
  const closeImageError = () => {
    setImageUploadError(false);
  };

  const descriptionLength = watch("description")?.length || 0;

  return (
    <FormProvider {...formMethods}>
      <form className={"form Page1Initial"} onSubmit={handleSubmit(onSubmit)}>
        <fieldset className={"formSection nameFieldSet"}>
          <Field
            autoComplete={"name"}
            label={"Name of listing"}
            name={"name"}
            required={true}
          />
        </fieldset>

        <div className={"formSection mainSection"}>
          <fieldset className={"imageFieldSet"}>
            <div className={"field required"}>
              <span className={"fieldLabel"}>
                <span className={"labelText"}>Image</span>
              </span>
              <div className="groupImage">
                {/* for showing images  */}
                {imagesArr.map((image, index) => {
                  return (
                    <div
                      className={
                        "fieldInput imageContainer" +
                        (image?.image ? "" : " addImage")
                      }
                    >
                      <div
                        className={"image"}
                        style={{
                          color: "blue",
                          backgroundImage: image ? `url(${image.image})` : "",
                        }}
                      >
                        {image?.loading === true && (
                          <SwurfSpinner loading={true} />
                        )}

                        <label>
                          <span>
                            {image?.image ? "Change image" : "Add image"}
                            <input
                              type="file"
                              onChange={(e) => onImageChange(e, index)}
                              className="image_uploader"
                            />
                          </span>
                        </label>
                        {image?.image && (
                          <label
                            className={"remove"}
                            onClick={() => onRemoveImage(index)}
                          >
                            <span>Remove</span>
                          </label>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={"helpText"}>
              Landscape photo in JPEG or PNG format recommended
            </div>
          </fieldset>

          <fieldset className={"detailsFieldSet"}>
            <TextArea
              label={"Description"}
              name={"description"}
              required={true}
              placeholder={
                "Need Inspiration...? Keep it brief and attract Swurfers with your unique charm. Here's one we " +
                "prepared earlier:\n" +
                "\n" +
                "A neighbourhood eatery in the heart of Edinburgh offering seasonal, modern bistro dishes all " +
                "day with an extensive drinks menu. It’s the kind of place you want hang out in all day, meet for " +
                "breakfast and work through to dinner, ideal for a curious Swurfer serious about getting stuff " +
                "done but wanting a fun and relaxed, welcoming vibe.\n" +
                "\n"
              }
              maxLength={1000}
            />
            <div className={"helpText"}>
              {descriptionLength}/1000 characters (about 100 words)
            </div>
          </fieldset>
        </div>

        <div className={"field pageControls"}>
          <div>
            <button>next</button>
          </div>
          {props.onCancel && (
            <div>
              <button
                className={"cancel"}
                onClick={(e) => {
                  props.onCancel();
                  e.preventDefault();
                }}
              >
                cancel
              </button>
            </div>
          )}
        </div>
      </form>
      {/* show popup when there is wrong image is uploading */}
      <Modal
        isOpen={imageUploadError}
        style={{
          overlay: {
            backgroundColor: "#00000055",
          },
        }}
      >
        <div className={"promotionPrompt"}>
          <h1>Something went wrong</h1>
          <p>
            There was an issue uploading the image you provided. Landscape photo
            in JPEG or PNG format recommended.
          </p>
          <div className={"buttons"}>
            <button onClick={closeImageError}>Close</button>
          </div>
        </div>
      </Modal>
    </FormProvider>
  );
}
