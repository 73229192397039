import {createSlice} from '@reduxjs/toolkit'
import {client} from "../../api/client";
import {selectToken} from "../auth/authSlice";

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: {
    isLoading: false,
    isComplete: true,
    errors: null,
  },
  reducers: {
    setLoading: state => {
      state.isLoading = true
    },
    setComplete: (state, action) => {
      state.isComplete = action.payload
      state.isLoading = false
      state.errors = null
    },
    error: (state, action) => {
      state.isLoading = false
      state.errors = action.payload
    }
  }
})

const { setLoading, error } = changePasswordSlice.actions

export const { setComplete } = changePasswordSlice.actions

export const performPasswordChange = (oldPassword, password1, password2) => async (dispatch, getState) => {
  console.log(`performing password change`)
  dispatch(setLoading())
  try {
    await client.post('rest-auth/password/change/', {
      old_password: oldPassword,
      new_password1: password1,
      new_password2: password2,
    }, selectToken(getState()))
    dispatch(setComplete(true))
  } catch (e) {
    dispatch(error(e.data))
  }
}

export const selectComplete = state => state.changePassword.isComplete

export const selectIsLoading = state => state.changePassword.isLoading

export const selectErrors = state => state.changePassword.errors

export default changePasswordSlice.reducer
