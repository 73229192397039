import "./SwurfTimes.scss"
import {Radio} from "../../../form/Radio";
import {
  ALL_HOURS,
  allHoursSelected,
  allNightTimeHoursSelected,
  anyHoursSelected,
  DAYS,
  HOURS,
  isDayTime,
  range
} from "../hostSlice";
import React, {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";

export const SwurfTimes = ({initialSwurfTimes, swurfTimes, setSwurfTimes}) => {
  const [hourHover, setHourHover] = useState([])
  const [show24Hours, setShow24Hours] = useState(false)
  const [show24HoursOverride, set24HoursOverride] = useState(!allNightTimeHoursSelected(initialSwurfTimes))
  const [anyNightTimeHoursBlocked, setAnyNightTimeHoursBlocked] = useState(!allNightTimeHoursSelected(initialSwurfTimes))

  const {watch} = useFormContext()
  const restricted = watch('restricted')

  useEffect(() => {
    if (restricted === "true") {
      setSwurfTimes(initialSwurfTimes)
    } else if (restricted === "false") {
      setSwurfTimes(ALL_HOURS)
    }
    // only ever want to run this effect if restricted changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restricted])

  const showHour = hour => show24HoursOverride || show24Hours || isDayTime(hour)

  useEffect(() => {
    const anyNightTimeHoursBlocked = !allNightTimeHoursSelected(swurfTimes);
    setShow24Hours(anyNightTimeHoursBlocked || show24HoursOverride)
    setAnyNightTimeHoursBlocked(anyNightTimeHoursBlocked)
    // only ever want to run this effect if swurfTimes changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swurfTimes, show24HoursOverride])

  return (
      <div className={"SwurfTimes"}>
        <div className={"restrictedContainer"}>
          <Radio
              label={"Best times to swurf"}
              helpText={"Control your visibility to swurfers"}
              name={"restricted"}
              required={true}
              options={[
                {label: "Swurfers are always welcome (recommended)", value: false},
                {label: "Block out swurf times", value: true},
              ]}
              registerOptions={{
                validate: value => {
                  if (value === "true") {
                    if (allHoursSelected(swurfTimes))
                      return "You must choose at least one time below"
                    if (!anyHoursSelected(swurfTimes))
                      return "You can't block off every hour!"
                  }
                }
              }}
          />
          {restricted === "true" && (
              <React.Fragment>
                {!(show24Hours || show24HoursOverride) ? (
                    <button onClick={(e) => {
                      e.preventDefault()
                      set24HoursOverride(true)
                    }}>
                      Show full 24 hours
                    </button>
                ) : (anyNightTimeHoursBlocked ||
                    <button onClick={(e) => {
                      e.preventDefault()
                      set24HoursOverride(false)
                    }}>
                      Hide full 24 hours
                    </button>
                )}
              </React.Fragment>
          )}
        </div>
        {restricted === "true" && (
            <div className={"weekContainer"}>
              <div className={"week"}>
                <div className={"dayLabels"}>
                  <div className={"dayName"} onMouseOver={() => {
                    setHourHover(HOURS)
                  }} onMouseOut={() => {
                    setHourHover([])
                  }} onClick={() => {
                    if (allHoursSelected(swurfTimes))
                      setSwurfTimes({})
                    else
                      setSwurfTimes(ALL_HOURS)
                  }}>&nbsp;</div>
                  {HOURS.filter(showHour).map(hour => (
                      <div key={hour} className={`hourName ${hour}`} onClick={() => {
                        const newState = {...swurfTimes}
                        const allSelected = range(DAYS.length).reduce((selected, day) => {
                          return selected && newState[day] && newState[day][hour]
                        }, true);
                        DAYS.forEach((_, day) => {
                          newState[day] = {...newState[day], [hour]: allSelected ? undefined : true}
                        })
                        setSwurfTimes(newState)
                      }} onMouseOver={() => {
                        setHourHover([hour])
                      }} onMouseOut={() => {
                        setHourHover([])
                      }}>
                        {String(hour).padStart(2, "0")}:00
                      </div>
                  ))}
                </div>
                {DAYS.map((dayName, day) => {
                  const hours = swurfTimes[day] || {}
                  return (
                    <div key={day} className={`day ${dayName}`}>
                      <div className={"dayName"} onClick={() => {
                        if (HOURS.reduce((selected, hour) => selected && hours[hour], true)) {
                          setSwurfTimes({...swurfTimes, [day]: {}})
                        } else {
                          setSwurfTimes({...swurfTimes, [day]: Object.fromEntries(HOURS.map(hour => [hour, true]))})
                        }
                      }}>{dayName.charAt(0)}<span className={"fullDayName"}>{dayName.substring(1)}</span></div>
                      {HOURS.filter(showHour).map(hour => (
                          <div key={hour} className={`hour ${hour} ${hours[hour] ? "" : "selected"} ${hourHover.indexOf(hour) !== -1 ? "hover" : ""}`}
                               onClick={() => {
                                 const newState = {[day]: {}, ...swurfTimes}
                                 if (newState[day][hour])
                                  newState[day][hour] = undefined
                                 else
                                   newState[day][hour] = true
                                 setSwurfTimes(newState)
                               }}/>
                      ))}
                    </div>
                  )}
                )}
              </div>
              <div className={"helpText weekHelpText"}>Need to block out the same time every day? Click the time row heading.</div>
            </div>
        )}
      </div>
  );
}
