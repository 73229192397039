import React from "react";
import {SpinnerComponent} from "react-element-spinner";
import {lavendar} from "../constants/colors";

export const SwurfSpinner = (props) => {
  const spinnerProps = {...props}
  if (spinnerProps.position === undefined)
    spinnerProps.position = "centered"
  if (spinnerProps.color === undefined)
    spinnerProps.color = lavendar
  if (spinnerProps.loading === undefined)
    spinnerProps.loading = true
  return <SpinnerComponent {...spinnerProps} />
}
