import React from "react";
import {CreateUpdateBusiness} from "./CreateUpdateBusiness";
import {useSelector} from "react-redux";
import {selectBusinessUpdating} from "./businessesSlice";
import {useBusiness} from "./businessSlice";
import {useHistory, useLocation} from "react-router-dom";
import {SwurfSpinner} from "../../components/SwurfSpinner";
import "./EditBusiness.scss"

export const EditBusiness = () => {
  const business = useBusiness()
  const loading = useSelector(selectBusinessUpdating)
  const history = useHistory()
  const location = useLocation()
  const next = location.state?.next

  return (
      <div className={"EditBusiness contentArea"}>
        <SwurfSpinner loading={loading} />
        <div className={"content"}>
          <CreateUpdateBusiness business={business} onSave={() => {
            if (next) history.push(next)
          }} onCancel={next ? () => {
            history.push(next)
          }: null} />
        </div>
      </div>
  )
}
