import {handleServerSubmit, useServerErrors} from "../../form/formErrors";
import React from "react";
import {ErrorMessage} from "@hookform/error-message";
import {Field} from "../../form/Field";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {performProfileUpdate, selectProfileUpdateErrors, selectUser} from "../auth/authSlice";

export const EditProfile = () => {
  const dispatch = useDispatch()
  const formMethods = useForm({
    defaultValues: {
      name: useSelector(selectUser).name
    },
  })
  const {errors, setError} = formMethods

  const apiErrors = useSelector(selectProfileUpdateErrors)

  useServerErrors(setError, apiErrors)

  const onSubmit = values => {
    dispatch(performProfileUpdate(values.name))
  }

  return (
      <FormProvider {...formMethods}>
        <form className={"form EditProfile"} onSubmit={handleServerSubmit(formMethods, onSubmit)}>
          <h2>About you</h2>
          <ErrorMessage className="error-message" name="non_field_errors" as="div" errors={errors}/>
          <Field
              label="Your name"
              name={"name"}
              required={true}
          />
          <div className={"field"}>
            <button>Update profile</button>
          </div>
        </form>
      </FormProvider>
  )
}