import {useEffect, useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import AsyncSelect from 'react-select/async';

const autocompletionRequestBuilder = (autocompletionRequest, input, sessionToken) => {
  const {bounds, location, ...rest} = autocompletionRequest

  const res = {
    input,
    ...rest
  }

  if (sessionToken) {
    res.sessionToken = sessionToken
  }

  if (bounds) {
    res.bounds = new window.google.maps.LatLngBounds(...bounds)
  }

  if (location) {
    res.location = new window.google.maps.LatLng(location)
  }

  return res
}

export const GooglePlaces = ({
                               autocompletionRequest = {},
                               debounce = 300,
                               minLengthAutocomplete = 1,
                               selectProps = {},
                               onChange,
                               onLoadFailed = console.error,
                               sessionToken,
                             }) => {
  const [value, setValue] = useState()
  const [placesService, setPlacesService] = useState(undefined)
  const fetchSuggestions = useDebouncedCallback((value, cb) => {
    if (!placesService) return cb([])
    if (value.length < minLengthAutocomplete) return cb([])

    const autocompletionReq = {...autocompletionRequest}

    placesService.getPlacePredictions(
        autocompletionRequestBuilder(
            autocompletionReq,
            value,
            sessionToken,
        ), (suggestions) => {
          cb((suggestions || []).map(suggestion => ({label: suggestion.description, value: suggestion})))
        },
    )
  }, debounce)

  useEffect(() => {
    if (!window.google) {
      onLoadFailed('[react-google-places-autocomplete]: Google script not loaded')
      return
    }
    if (!window.google.maps) {
      onLoadFailed('[react-google-places-autocomplete]: Google maps script not loaded')
      return
    }
    if (!window.google.maps.places) {
      onLoadFailed('[react-google-places-autocomplete]: Google maps places script not loaded')
      return
    }
    setPlacesService(new window.google.maps.places.AutocompleteService())
  }, [onLoadFailed])

  const onChangeLocal = (e) => {
    setValue(e?.value.description)
    onChange(e)
  }

  return (
      <AsyncSelect
          {...selectProps}
          value={value}
          defaultOptions={true}
          onChange={onChangeLocal}
          isClearable={true}
          loadOptions={fetchSuggestions.callback}
      />
  )
}
