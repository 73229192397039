import {Link} from "react-router-dom";
import React from "react";
import "./PaymentReturn.scss"

export const PaymentCancelled = () => {
  return (
      <div className={"PaymentCancelled contentArea"}>
        <div className={"content"}>
          <p>Sorry, there seems to have been a problem setting up your subscription!</p>
          <Link className={"button"} to={"../"}>Retry</Link>
          <Link className={"button"} to={"/"}>Return home</Link>
        </div>
      </div>
  )
}
