import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { updateFormState } from "../hostSlice";
import {
  useCategories,
  useFacilities,
  useTypesOfWork,
} from "../../data/slices";
import { CheckboxList } from "../../../form/CheckboxList";
import { Radio } from "../../../form/Radio";
import "./Page5Swurfer.scss";
import { useHistory } from "react-router-dom";
import { TextArea } from "../../../form/TextArea";

export function Page5Swurfer(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories } = useCategories();
  const { typesOfWork } = useTypesOfWork();
  const { facilities } = useFacilities();
  const [showDescription, SetShowDescription] = useState(false);
  useEffect(() => {
    document.getElementsByClassName("contentArea")[0].scroll({ top: 0 });

  }, []);
  const host = props.host;

  const defaultValues = { ...props.defaultValues };
  if (defaultValues.category)
    defaultValues.category = defaultValues.category.toString();
  if (defaultValues.type_of_work)
    defaultValues.type_of_work = defaultValues.type_of_work.length
      ? defaultValues.type_of_work[0].toString()
      : undefined;
  if (defaultValues.facilities)
    defaultValues.facilities = defaultValues.facilities.map((v) =>
      v.toString()
    );

  const formMethods = useForm({
    defaultValues: defaultValues,
  });
  const { handleSubmit, watch } = formMethods;

  const [submitAction, setSubmitAction] = useState();

  useEffect(() => {
    if(props.defaultValues.category ==1){
      SetShowDescription(false);
    }else{
      SetShowDescription(true);
    }
  }, [props.defaultValues]);

  const onSubmit = (values) => {
    values.type_of_work = [values.type_of_work];
    dispatch(updateFormState(host, values));
    if (submitAction === "back") history.push("../page4/");
    else history.push("../page6/");
  };
  const inputHandler = (selectedValue) => {
    if (selectedValue == 1) {
      SetShowDescription(false);
    } else {
      SetShowDescription(true);
    }
    // Casual category
    // ... (other logic)
  };
  const descriptionLength = watch("paid_space")?.length || 0;
  return (
    <FormProvider {...formMethods}>
      <form className={"form Page4Swurfer"} onSubmit={handleSubmit(onSubmit)}>
        <fieldset className={"formSection swurferFieldSet"}>
          <h3 className={"withDescription"}>
            Let Swurfers know what they can expect from your space
          </h3>
          <div className={"pageDescription"}>
            Now for the detail. Help us match the right Swurfers for your space.
          </div>
          <Radio
            label={"Category"}
            name={"category"}
            required={true}
            onChange={inputHandler}
            options={categories?.map((category) => {
              return {
                label: category.name,
                description: category.description,
                value: category.id,
              };
            })}
            registerOptions={{
              valueAsNumber: true,
            }}
          />
          {showDescription && (
            <>
            <div className="page5Textarea">
              <TextArea
                name={"paid_space"}
                required={false}
                placeholder={
                  "Provide a short summary of how swurfers can expect to be charged for using your space."
                }
                maxLength={1000}
              />
            </div>
          
          <div className={"helpText"}>
            {descriptionLength}/1000 characters (about 100 words)
          </div>
          </>
          )}
          <Radio
            label={"Describe your environment"}
            name={"type_of_work"}
            required={true}
            helpText={"Select the one that reflects your vibe best"}
            options={typesOfWork?.map((category) => {
              return { label: category.name, value: category.id };
            })}
          />

          <CheckboxList
            label={"Facilities"}
            name={"facilities"}
            helpText={"Select as many as are applicable"}
            options={facilities?.map((category) => {
              return { label: category.name, value: category.id };
            })}
            registerOptions={{
              valueAsNumber: true,
            }}
          />
        </fieldset>

        <div className={"field pageControls"}>
          <div>
            <button onClick={() => setSubmitAction("back")}>back</button>
            <button onClick={() => setSubmitAction("next")}>next</button>
          </div>
          {props.onCancel && (
            <div>
              <button
                className={"cancel"}
                onClick={(e) => {
                  props.onCancel();
                  e.preventDefault();
                }}
              >
                cancel
              </button>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
