import React, { useEffect, useState } from "react";
import "./CreateUpdateHost.scss";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Redirect, useRouteMatch } from "react-router";
import { Page1Initial } from "./Page1Initial";
import { Page2Location } from "./Page2Location";
import { Page3Contact } from "./Page3Contact";
import { Page4Times } from "./Page4Times";
import { Page5Swurfer } from "./Page5Swurfer";
import { Page6Promotion } from "./Page6Promotion";
import { Page7Reservation } from "./Page7Reservation";
import { Page8Payment } from "./Page8Payment";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFormState,
  formDataByHost,
  performCreateHost,
  performUpdateHost,
  selectForms,
  selectIsLoading,
  useHosts,
} from "../hostSlice";
import { useBusiness } from "../../business/businessSlice";
import { SwurfSpinner } from "../../../components/SwurfSpinner";
import {
  useCategories,
  useFacilities,
  useStrings,
  useTypesOfWork,
} from "../../data/slices";
import { Checkout } from "./Checkout";
import { Promotion } from "./Page10Promote";

export function CreateUpdateHost(props) {
  const dispatch = useDispatch();
  const business = useBusiness();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const next = location.state?.next;

  const host = props.host;
  const onSave = props.onSave;

  const forms = useSelector(selectForms);
  const [formData, setFormData] = useState();

  useEffect(() => {
    setFormData(formDataByHost(forms, host));
  }, [forms, setFormData, host]);

  const defaultValues = host
    ? {
        ...host,
        ...formData,
      }
    : {
        email: business?.contact_email,
        ...formData,
      };

  if (defaultValues.images?.length) {
    defaultValues.image = defaultValues.images[0];
  }

  useHosts(); // ensure hosts are loaded
  const dataLoaded =
    useCategories().isLoaded |
    useTypesOfWork().isLoaded |
    useFacilities().isLoaded |
    useStrings().isLoaded;
  const loading = useSelector(selectIsLoading) | !dataLoaded;

  const processFormData = (formData) => {
    // Create a shallow copy of the formData to avoid direct modification
    let data = { ...formData };
    // Check if the images property exists in the formData
    if (formData.images) {
      // Extract and filter the ids from the images array
      let filteredIds = formData.images
        .map((obj) => obj?.id)
        .filter((id) => id != null);
      // Update the images property in the copied data object
      data.images = filteredIds;
    } else {
      // If images property doesn't exist, set it to an empty array
      data.images = [];
    }
    // Return the processed data object

    return data;
  };

  const onPage7Save = async (formData) => {
    if (host) {
      let data = processFormData(formData);
      let updatedHost;
      try {
        updatedHost = await dispatch(performUpdateHost(host.id, data));
      } catch (e) {
        console.error("error updating host", host, e);
        return;
      }
      dispatch(clearFormState(host));
      onSave?.(updatedHost);
    } else {
      history.push("../page9/");
    }
  };

  const onCheckout = async (formData) => {
    let data = processFormData(formData);
    let newHost;
    try {
      newHost = await dispatch(performCreateHost(data));
    } catch (e) {
      console.error("error creating host", e);
      return;
    }
    dispatch(clearFormState(null));
    onSave?.(newHost);
  };

  const onCancel = () => {
    if (window.confirm("Are you sure you want to cancel?")) {
      dispatch(clearFormState(host));
      if (next) history.push(next);
      else history.push("/");
    }
  };

  return (
    <div className={"CreateUpdateHost"}>
      <SwurfSpinner loading={loading} />
      <Switch>
        <Route path={`${match.url}`} exact>
          <Page1Initial
            host={props.host}
            defaultValues={defaultValues}
            onCancel={onCancel}
          />
        </Route>
        <Route path={`${match.url}/page2/`} exact>
          <Page2Location
            host={props.host}
            defaultValues={defaultValues}
            onCancel={onCancel}
          />
        </Route>
        <Route path={`${match.url}/page3/`} exact>
          <Page3Contact
            host={props.host}
            defaultValues={defaultValues}
            onCancel={onCancel}
          />
        </Route>
        <Route path={`${match.url}/page4/`} exact>
          <Page4Times
            host={props.host}
            defaultValues={defaultValues}
            onCancel={onCancel}
          />
        </Route>
        <Route path={`${match.url}/page5/`} exact>
          <Page5Swurfer
            host={props.host}
            defaultValues={defaultValues}
            onCancel={onCancel}
          />
        </Route>
        <Route path={`${match.url}/page6/`} exact>
          <Page6Promotion
            host={props.host}
            defaultValues={defaultValues}
            onCancel={onCancel}
          />
        </Route>
        <Route path={`${match.url}/page7/`} exact>
          <Page7Reservation
            host={props.host}
            defaultValues={defaultValues}
            onCancel={onCancel}
          />
        </Route>
        {/* this page is added for social promotions */}
        <Route path={`${match.url}/page8/`} exact>
          <Promotion
            host={props.host}
            defaultValues={defaultValues}
            onSave={onPage7Save}
            onCancel={onCancel}
          />
        </Route>
        {!host && (
          <React.Fragment>
            <Route path={`${match.url}/page9/`} exact>
              <Page8Payment
                host={props.host}
                defaultValues={defaultValues}
                onCancel={onCancel}
              />
            </Route>
            <Route path={`${match.url}/checkout/`} exact>
              <Checkout
                host={props.host}
                defaultValues={defaultValues}
                onCheckout={onCheckout}
                onCancel={onCancel}
              />
            </Route>
          </React.Fragment>
        )}
        <Redirect to={`${match.url}/`} />
      </Switch>
    </div>
  );
}
