import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {updateFormState} from "../hostSlice";
import "./Page6Promotion.scss"
import {useHistory} from "react-router-dom";
import {Field} from "../../../form/Field";
import {TextArea} from "../../../form/TextArea";
import Modal from 'react-modal';
import {getString, useStrings} from "../../data/slices";

export function Page6Promotion(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const {strings} = useStrings()
  const termsHTML = getString(strings, "PROMO_TERMS")
  const terms = termsHTML ? (() => {
    try {
      return new DOMParser().parseFromString(
          termsHTML, 'text/html'
      ).children[0].innerText.split("\n")
    } catch {
      return []
    }
  })() : []

  useEffect(() => {
    document.getElementsByClassName('contentArea')[0].scroll({top: 0})
  }, [])

  const host = props.host

  const [promptOpen, setPromptOpen] = useState(false);
  const [showPrompt, setShowPrompt] = useState(!host);

  const promotions = props.defaultValues.promotions;
  let defaultValues = promotions?.length ? (
      {...promotions[0]}
  ) : (
      {}
  )

  const formMethods = useForm({
    defaultValues: defaultValues,
  })
  const {handleSubmit, register, clearErrors} = formMethods

  const [submitAction, setSubmitAction] = useState()
  const onSubmit = async values => {
    if (values.id === "")
      values.id = undefined
    let promotions = []
    if (Object.keys(values).length)
      promotions.push(values)
    await dispatch(updateFormState(host, {
      promotions: promotions,
    }))

    if (submitAction === "back")
      history.push("../page5/")
    else {
      if (showPrompt && promotions.length === 0) {
        setShowPrompt(false)
        setPromptOpen(true)
      } else {
        history.push("../page7/")
      }
    }
  }

  const skipPrompt = () => {
    history.push("../page7/")
  }

  const acceptPrompt = () => {
    setPromptOpen(false)
  }

  return (
      <FormProvider {...formMethods}>
        <form className={"form Page6Promotion"} onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={"formSection promotionFieldSet"}>
            {promotions?.length ? (
                <React.Fragment>
                  <h3>Attract swurfers with an offer!</h3>
                  {promotions.map((promotion, i) => (
                      <React.Fragment key={i.id || `new-${i}`}>
                        <input
                            type={"hidden"}
                            name={"id"}
                            ref={register()}
                        />
                        <Field
                            label={"Promotion Detail"}
                            name={"description"}
                            helpText={"i.e. 10% off for swurfers"}
                            required={true}
                        />

                        <Field
                            label="Promotional code"
                            helpText={"Promotional code that can be revealed to access the offer (i.e. SWURF10)"}
                            name={"code"}
                            registerOptions={{
                              maxLength: {value: 10, message: "Maximum length 10 characters"},
                            }}
                            inputProps={{maxLength: 10}}
                            required={true}
                        />

                        <div className={`field required`}>
                          <label>
                            <span className={'fieldLabel'}>
                              <span className={'labelText'}>Terms and conditions</span>
                            </span>
                            <div className={"termsIntro"}>
                              The following terms and conditions are displayed by default in addition to any terms you
                              supply below
                            </div>
                            {terms?.length ? terms.map((line, i) => (
                                <div key={i} className={"defaultTerms"}>{line}</div>
                            )) : (
                                <React.Fragment>
                                  <div className={"defaultTerms"}>
                                    Promotions are offered by each listing at their discretion and are subject to
                                    availability.
                                  </div>
                                  <div className={"defaultTerms"}>
                                    Management reserve the right to refuse or withdraw at any time.
                                  </div>
                                  <div className={"defaultTerms"}>Swurf Ltd
                                    accepts no liability or responsibility for offers or promotions provided by Host venues.
                                  </div>
                                  <div className={"defaultTerms"}>
                                    Promotions are valid on redemption of the promotional code displayed in the Swurf app and
                                    must be presented at time of redemption, before payment.
                                  </div>
                                  <div className={"defaultTerms"}>
                                    Valid for Swurfers only.
                                  </div>
                                </React.Fragment>
                            )}
                          </label>
                        </div>

                        <TextArea
                            label={"Additional terms and conditions"}
                            name={"terms_and_conditions"}
                        />
                        <div className={"field"}>
                          <button onClick={(e) => {
                            e.preventDefault()
                            clearErrors()
                            dispatch(updateFormState(host, {
                              promotions: []
                            }))
                          }}>Remove promotion</button>
                        </div>
                      </React.Fragment>
                  ))}
                </React.Fragment>
            ): (
                <React.Fragment>
                  <h3 className={"withDescription"}>Attract swurfers with an offer!</h3>
                  <div className={"pageDescription"}>Amazing! You are nearly there with your first listing. Now the exciting bit. Add a promotion to attract Swurfers to your venue space. Think about when you most want to welcome them to support your business. Be creative and imaginative.</div>
                  <div className={"field"}>
                    <button onClick={(e) => {
                      e.preventDefault()
                      dispatch(updateFormState(host, {
                        promotions: [{}]
                      }))
                    }}>Add promotion</button>
                  </div>
                </React.Fragment>
            )}
          </fieldset>

          <div className={"field pageControls"}>
            <div>
              <button onClick={() => setSubmitAction("back")}>back</button>
              <button onClick={() => setSubmitAction("next")}>next</button>
            </div>
            {props.onCancel && (
              <div>
                <button className={"cancel"} onClick={(e) => {
                  props.onCancel()
                  e.preventDefault();
                }}>
                  cancel
                </button>
              </div>
            )}
          </div>
        </form>
        <Modal
            isOpen={promptOpen}
            style={{
              overlay: {
                backgroundColor: '#00000055',
              }
            }}>
          <div className={"promotionPrompt"}>
            <h1>Hey, wait a minute.</h1>
            <p>You've missed your promotion are you sure you want to move on? Promotions are a great way to attract new customers.</p>
            <div className={"buttons"}>
              <button onClick={acceptPrompt}>Go back</button>
              <button onClick={skipPrompt}>Skip for now</button>
            </div>
          </div>
        </Modal>
      </FormProvider>
  );
}
