import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { updateFormState, validateURL } from "../hostSlice";
import "./Page7Reservation.scss";
import { useHistory } from "react-router-dom";

const NONE = "NONE";
const OTHER = "OTHER";
const RES_DIARY = "RES_DIARY";
const SEVENROOMS = "SEVENROOMS";
const OPEN_TABLE = "OPEN_TABLE";

export function Page7Reservation(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    document.getElementsByClassName("contentArea")[0].scroll({ top: 0 });
  }, []);

  const host = props.host;

  const formMethods = useForm({
    defaultValues: props.defaultValues,
  });
  const { handleSubmit, register, watch, setValue } = formMethods;

  const [submitAction, setSubmitAction] = useState();
  const onSubmit = async (values) => {
    await dispatch(updateFormState(host, values));
    if (submitAction === "back") {
      history.push("../page6/");
    } else {
      history.push("../page8/");
    }
  };

  let reservationLink = watch("reservation_link");
  let [showForm, setShowForm] = useState(false);
  let [formError, setFormError] = useState(false);
  let [provider, setProvider] = useState(NONE);

  let resDiaryMicrositeName = useRef();
  let sevenRoomsVenueID = useRef();
  let openTableRestRef = useRef();
  let otherLink = useRef();

  let updateLink = async () => {
    let url = null;
    switch (provider) {
      case RES_DIARY:
        url = `http://www.resdiary.com/Restaurant/${resDiaryMicrositeName.current.value}`;
        break;
      case SEVENROOMS:
        url = `https://www.sevenrooms.com/reservations/${sevenRoomsVenueID.current.value}`;
        break;
      case OPEN_TABLE:
        url = `https://www.opentable.com/restref/client/?restref=${openTableRestRef.current.value}&lang=en-US&ot_source=Swurf`;
        break;
      case OTHER:
        url = otherLink.current.value;
        break;
      case NONE:
        url = "";
        break;
      default:
        break;
    }
    if (
      url !== null &&
      (provider === NONE || (await dispatch(validateURL(url))))
    ) {
      setProvider(NONE);
      setValue("reservation_link", url, { shouldDirty: true });
      setShowForm(false);
      setFormError(false);
    } else {
      setFormError(true);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        className={"form Page7Reservation"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className={"formSection reservationFieldSet"}>
          <h3 className={"withDescription"}>Accept bookings</h3>
          <div className={"pageDescription"}>
            Add a "book now" button to your Swurf listing. This will direct
            Swurfers to your existing booking system.
          </div>
          <input type={"hidden"} name={"reservation_link"} ref={register()} />
          {showForm ? (
            <React.Fragment>
              <div className={"field required"}>
                <label>
                  <span className="fieldLabel">
                    <span className="labelText">Booking system</span>
                    <span className="helpText">
                      Choose the booking system you use
                    </span>
                  </span>
                </label>
                <div className={"fieldInput"}>
                  <select
                    value={provider}
                    onChange={(e) => {
                      setProvider(e.target.value);
                    }}
                  >
                    <option value={NONE}>None</option>
                    <option value={RES_DIARY}>ResDiary</option>
                    <option value={SEVENROOMS}>SEVENROOMS</option>
                    <option value={OPEN_TABLE}>OpenTable</option>
                    <option value={OTHER}>Other</option>
                  </select>
                </div>
                {formError && (
                  <div className="fieldError">Invalid reservation link</div>
                )}
              </div>
              {(() => {
                switch (provider) {
                  case RES_DIARY:
                    return (
                      <div className={"field required"}>
                        <label>
                          <span className="fieldLabel">
                            <span className="labelText">
                              ResDiary microsite name
                            </span>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <span className="helpText">
                              Please provide your ResDiary microsite name (see
                              the{" "}
                              <a
                                href={
                                  "https://sales.resdiary.com/get-free-mobile-website-resdiary-com/"
                                }
                                target={"_blank"}
                              >
                                ResDiary documentation for more information
                              </a>
                              )
                            </span>
                          </span>
                        </label>
                        <div className={"fieldInput"}>
                          <input type={"text"} ref={resDiaryMicrositeName} />
                        </div>
                      </div>
                    );
                  case SEVENROOMS:
                    return (
                      <div className={"field required"}>
                        <label>
                          <span className="fieldLabel">
                            <span className="labelText">
                              SEVENROOMS venue id
                            </span>
                            <span className="helpText">
                              Please provide your SEVENROOMS venue id
                            </span>
                          </span>
                        </label>
                        <div className={"fieldInput"}>
                          <input type={"text"} ref={sevenRoomsVenueID} />
                        </div>
                      </div>
                    );
                  case OPEN_TABLE:
                    return (
                      <div className={"field required"}>
                        <label>
                          <span className="fieldLabel">
                            <span className="labelText">
                              OpenTable Restaurant ID
                            </span>
                            <span className="helpText">
                              Please provide your OpenTable Restaurant ID
                            </span>
                          </span>
                        </label>
                        <div className={"fieldInput"}>
                          <input type={"text"} ref={openTableRestRef} />
                        </div>
                      </div>
                    );
                  case OTHER:
                    return (
                      <div className={"field required"}>
                        <label>
                          <span className="fieldLabel">
                            <span className="labelText">Booking link</span>
                            <span className="helpText">
                              Please provide a direct link to the booking page
                              for your host
                            </span>
                          </span>
                        </label>
                        <div className={"fieldInput"}>
                          <input type={"text"} ref={otherLink} />
                        </div>
                      </div>
                    );
                  default:
                    return null;
                }
              })()}
              <div className={"field"}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateLink();
                  }}
                >
                  update
                </button>
              </div>
              <div className={"field"}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowForm(false);
                    setProvider(NONE);
                  }}
                >
                  cancel
                </button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={"field required reservationLink"}>
                <label>
                  <span className="fieldLabel">
                    <span className="labelText">Booking button</span>
                    <span className="helpText">
                      {reservationLink
                        ? 'This is your current "book now" button.'
                        : 'Click below to setup a "book now" button'}
                    </span>
                  </span>
                </label>
                <div className={"fieldInput"}>
                  {reservationLink && (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a
                      className={"button"}
                      href={reservationLink}
                      target={"_blank"}
                    >
                      Book now
                    </a>
                  )}
                </div>
              </div>

              <div className={"field"}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowForm(true);
                  }}
                >
                  {reservationLink
                    ? "change booking button"
                    : "add booking button"}
                </button>
              </div>
            </React.Fragment>
          )}
        </fieldset>

        {!showForm && (
          <div className={"field pageControls"}>
            <div>
              <button onClick={() => setSubmitAction("back")}>back</button>
              <button onClick={() => setSubmitAction("next")}>next</button>
            </div>
            {props.onCancel && (
              <div>
                <button
                  className={"cancel"}
                  onClick={(e) => {
                    props.onCancel();
                    e.preventDefault();
                  }}
                >
                  cancel
                </button>
              </div>
            )}
          </div>
        )}
      </form>
    </FormProvider>
  );
}
