import {ErrorMessage} from "@hookform/error-message";
import React from "react";
import "./Field.scss"
import {useFormContext} from "react-hook-form";

export const CheckboxList = ({label, name, helpText, options=[], required=false, registerOptions={}, ...props}) => {
  const {errors, register} = useFormContext()

  if (required && registerOptions.required === undefined)
    registerOptions.required = required === true ? "This field is required" : required

  return (
      <div className={`field ${errors.name ? 'error' : ''} ${required ? 'required' : ''} CheckboxList`}>
        <label>
          <span className={'fieldLabel'}>
            <span className={'labelText'}>{label}</span>
            {helpText && (
                <span className={"helpText"}>{helpText}</span>
            )}
            {props.children}
          </span>
        </label>
        <span className={'fieldInput'}>
            {options.map(({label, description, value}) => (
              <label key={value}>
                <input type={"checkbox"} name={name} value={value} ref={register(registerOptions)} />
                <div className={"label"}>
                  <div className={"name"}>{label}</div>
                  {description && (
                      <div className={"description"}>{description}</div>
                  )}
                </div>
              </label>
            ))}
        </span>
        <ErrorMessage className="fieldError" name={name} as="div" errors={errors} />
      </div>
  )
}
