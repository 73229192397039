// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
import Cookies from "js-cookie";

export async function client(path, token = null, { body, ...customConfig } = {}) {
  let endpoint = process.env.REACT_APP_SWURF_API_ENDPOINT
  const url = `${endpoint}${path}`

  const headers = {}
  if (body && !(body instanceof FormData))
    headers['Content-Type'] = 'application/json'

  if (token !== null)
    headers['Authorization'] = `token ${token}`

  const csrftoken = Cookies.get('csrftoken');
  if (csrftoken)
    headers['X-CSRFToken'] = csrftoken

  const config = {
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    if (body instanceof FormData)
      config.body = body
    else
      config.body = JSON.stringify(body)
  }

  let data
  try {
    const response = await window.fetch(url, config)
    data = await response.json()
    if (response.ok)
      return data

    console.log(`API error: ${response.status} (${response.statusText})\n${JSON.stringify(data)}`)
    return Promise.reject({response, data})
  } catch (err) {
    return Promise.reject(err.message ? err.message : data)
  }
}

client.get = function (endpoint, token = null, customConfig = {}) {
  return client(endpoint, token, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, token = null, customConfig = {}) {
  return client(endpoint, token, { ...customConfig, body, method: 'POST' })
}

client.put = function (endpoint, body, token = null, customConfig = {}) {
  return client(endpoint, token, { ...customConfig, body, method: 'PUT' })
}
