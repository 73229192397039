import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {useServerErrors, handleServerSubmit} from "../../form/formErrors";
import {ErrorMessage} from "@hookform/error-message";
import {performCreateBusiness, performUpdateBusiness, selectErrors} from "./businessesSlice";
import {selectUser} from "../auth/authSlice";
import {Field} from "../../form/Field";

// import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export function CreateUpdateBusiness(props) {
  const dispatch = useDispatch()
  const business = props.business
  const user = useSelector(selectUser);
  const defaultValues = business ? business : {
    "contact_email": user.email,
  }
  const formMethods = useForm({
    defaultValues: defaultValues,
  })
  const {errors, setError} = formMethods
  const apiErrors = useSelector(selectErrors)

  useServerErrors(setError, apiErrors)

  const onSubmit = async values => {
    if (business)
      await dispatch(performUpdateBusiness(business.id, values))
    else
      await dispatch(performCreateBusiness(values))
    props.onSave?.()
  }

  return (
      <FormProvider {...formMethods}>
        <form className={"form CreateUpdateBusiness"} onSubmit={handleServerSubmit(formMethods, onSubmit)}>
          <ErrorMessage className="error-message" name="non_field_errors" as="div" errors={errors}/>

          <fieldset>
            <h3>Registered company details</h3>

            <Field
                autoComplete={"name"}
                label={"Company name"}
                name={"name"}
                required={true}
            />

            <Field
                type={"email"}
                label="Contact email"
                name={"contact_email"}
                required={true}
            />

            <Field
                type={"email"}
                label="Finance email"
                name={"finance_email"}
                helpText={"Email address for billing and invoicing (leave blank to use contact email)"}
            />

            <Field
                autocomplete={"phone"}
                label="Phone"
                name={"phone"}
            />

            <Field
                label="Company number"
                name={"company_number"}
            />

            <Field
                label="VAT number"
                name={"vat_number"}
            />
          </fieldset>

          <fieldset>
            <h3>Registered company address</h3>

            {/* TODO <GooglePlacesAutocomplete*/}
            {/*    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}*/}
            {/*    withSessionToken={true}*/}
            {/*/>*/}

            <Field
                label="Street address"
                name={"street_address"}
                required={true}
            />

            <Field
                label="Town or city"
                name={"city"}
                required={true}
            />

            <Field
                label="Region"
                name={"region"}
            />

            <Field
                label="Postcode"
                name={"postcode"}
                required={true}
            />

            <Field
                label="Country"
                name={"country"}
                required={true}
            />
          </fieldset>

          <div className={"field"}>
            <button>{business ? "save" : "create"}</button>
            {props.onCancel && <button onClick={(e) => {
              props.onCancel()
              e.preventDefault();
            }}>cancel</button>}
          </div>
        </form>
      </FormProvider>
  );
}
