import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {DAYS, updateFormState} from "../hostSlice";
import "./Page6Promotion.scss"
import {Link, useHistory} from "react-router-dom";
import {CheckboxList} from "../../../form/CheckboxList";
import "./Checkout.scss"
import {FauxField} from "../../../form/FauxField";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import {swurfMapId} from "../../../constants/constants";
import {useCategories, useFacilities, useTypesOfWork} from "../../data/slices";
import {Plan} from "../../paypal/Plan";

export function Checkout(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    document.getElementsByClassName('contentArea')[0].scroll({top: 0})
  }, [])

  const {categories} = useCategories()
  const {typesOfWork} = useTypesOfWork()
  const {facilities} = useFacilities()

  const host = props.host
  const onCheckout = props.onCheckout

  let defaultValues = {
    ...props.defaultValues,
    terms_and_conditions_accepted: false,
  }


  const formMethods = useForm({
    defaultValues: defaultValues,
  })
  const {handleSubmit} = formMethods

  const [submitAction, setSubmitAction] = useState()
  const onSubmit = async values => {
    let formData = await dispatch(updateFormState(host, values))
    if (submitAction === "back")
      history.push("../page9/")
    else if (submitAction === "checkout")
      onCheckout?.(formData)
  }


  const {isLoaded: isMapScriptLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    mapIds: [swurfMapId],
  })

  const marker = {lat: Number.parseFloat(defaultValues.latitude), lng: Number.parseFloat(defaultValues.longitude)}

  const blockedSwurfTimes = (() => {
    const blocked = []
    let weekday = null
    let start = null
    defaultValues.swurf_times?.forEach(swurfTime => {
      if (start !== null) {
        blocked.push({weekday, start, end: swurfTime.start.split(":", 2).join(":")})
        start = null
        weekday = swurfTime.weekday
      }
      if (swurfTime.start !== "00:00:00" && weekday !== swurfTime.weekday) {
        blocked.push({weekday: swurfTime.weekday, start: "00:00", end: swurfTime.start.split(":", 2).join(":")})
      } else if (swurfTime.end !== "23:59:59") {
        start = swurfTime.end.split(":", 2).join(":")
        weekday = swurfTime.weekday
      }
    })

    if (start !== null)
      blocked.push({weekday, start, end: "00:00"})
    return blocked
  })()

  return (
      <FormProvider {...formMethods}>
        <form className={"form Checkout"} onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <h3 className={"withDescription"}>Checkout</h3>
            <div className={"pageDescription"}>Review your new listing</div>
          </fieldset>

          <fieldset className={"formSection"}>
            <div className={"sectionHeader"}>
              <h4>Name and description</h4>
              <Link to={"../"}>edit details</Link>
            </div>

            <FauxField
              name={"name"}
              label={"Name"}
              required={true}>
              {defaultValues.name}
            </FauxField>

            <FauxField
              name={"description"}
              label={"Description"}
              required={true}>
              {defaultValues.description}
            </FauxField>
          </fieldset>

          <fieldset className={"formSection location"}>
            <div className={"sectionHeader"}>
              <h4>Location</h4>
              <Link to={"../page2/"}>edit details</Link>
            </div>
            <div className={"locationAndMap"}>
              <FauxField
                label={"Address"}
                required={true}>
                <div>{defaultValues.street_address}</div>
                <div>{defaultValues.city}</div>
                <div>{defaultValues.region}</div>
                <div>{defaultValues.country}</div>
                <div>{defaultValues.postcode}</div>
              </FauxField>
              <div className={"mapContainer"}>
                {isMapScriptLoaded && (
                  <GoogleMap
                      mapContainerStyle={{
                        width: '100%',
                        height: '100%',
                      }}
                      options={{
                        mapId: swurfMapId,
                        disableDefaultUI: true,
                        zoomControl: true,
                      }}
                      zoom={17}
                      center={marker}>
                    <Marker position={marker} />
                  </GoogleMap>
                )}
              </div>
            </div>
          </fieldset>

          <fieldset className={"formSection"}>
            <div className={"sectionHeader"}>
              <h4>Contact details</h4>
              <Link to={"../page3/"}>edit details</Link>
            </div>
            <FauxField
                label={"Email"}
                required={true}
            >
              {defaultValues.email}
            </FauxField>
            <FauxField
                label="Phone number"
                required={true}
            >
              {defaultValues.phone || "Not specified"}
            </FauxField>
          </fieldset>

          <fieldset className={"formSection bestTimesToSwurf"}>
            <div className={"sectionHeader"}>
              <h4>Best times to swurf</h4>
              <Link to={"../page4/"}>edit details</Link>
            </div>
            {blockedSwurfTimes.length ? (
                <React.Fragment>
                  <div>You have chosen to block your visibility to swurfers between the following times:</div>
                  {DAYS.map((day, weekday) => (
                      <div className={"day"} key={weekday}>
                        <div className={"label"}>{day}:</div>
                        <div className={"times"}>
                          {blockedSwurfTimes.filter(t => t.weekday === weekday).length ? (
                              <React.Fragment>
                                {blockedSwurfTimes.filter(t => t.weekday === weekday).map((t, j) => (
                                    <div className={"time"} key={j}>{t.start} - {t.end}</div>
                                ))}
                              </React.Fragment>
                          ) : (
                              <React.Fragment>No restrictions</React.Fragment>
                          )}
                        </div>
                      </div>
                  ))}
                </React.Fragment>
            ) : (
                <div>Swurfers always welcome!</div>
            )}
          </fieldset>

          <fieldset className={"formSection space"}>
            <div className={"sectionHeader"}>
              <h4>Your space</h4>
              <Link to={"../page5/"}>edit details</Link>
            </div>

            <FauxField
              name={"category"}
              label={"Category"}
              required={true}>
              {categories?.find(c => c.id === Number.parseInt(defaultValues.category))?.name}
            </FauxField>

            <FauxField
              name={"environment"}
              label={"Environment"}
              required={true}>
              {typesOfWork?.find(t => t.id === Number.parseInt(defaultValues.type_of_work))?.name}
            </FauxField>

            <FauxField
              name={"facilities"}
              label={"Facilities"}
              required={true}>
              {defaultValues.facilities?.length ? defaultValues.facilities.map(selectedFacility => (
                  <div key={selectedFacility}>
                    {facilities?.find(facility => facility.id === Number.parseInt(selectedFacility))?.name}
                  </div>
              )) : "None selected"}
            </FauxField>
          </fieldset>

          <fieldset className={"formSection promotions"}>
            <div className={"sectionHeader"}>
              <h4>Promotions</h4>
              <Link to={"../page6/"}>edit details</Link>
            </div>
            {defaultValues.promotions?.length ? (
                <div className={"promotionsList"}>
                  {defaultValues.promotions.map((promotion, i) => (
                      <div key={i} className={"promotion"}>
                        <span className={"title"}>Active promotion</span>
                        <span className={"code"}>{promotion.code}</span>
                        <span className={"description"}>{promotion.description}</span>
                      </div>
                  ))}
                </div>
            ) : "You not have chosen to offer swurfers a promotion"}
          </fieldset>


          <fieldset className={"formSection subscription"}>
            <div className={"sectionHeader"}>
              <h4>Your subscription</h4>
              <Link to={"../page9/"}>change subscription</Link>
            </div>
            {defaultValues.plan && (
              <div className={"planContainer"}>
                <Plan plan={defaultValues.plan} promotionCode={defaultValues.promotionCode} />
              </div>
            )}
          </fieldset>

          <fieldset className={"formSection payment"}>
            <div className={"sectionHeader"}>
              <h4>Payment</h4>
              <Link to={"../page9/"}>change payment method</Link>
            </div>
            <div>
              {{
                INVOICE: "Payment via invoice",
                PAYPAL: "Payment via PayPal",
              }[defaultValues.payment_method]}
            </div>
          </fieldset>

          <fieldset className={"formSection terms"}>
            <div className={"sectionHeader"}>
              <h4>Terms and conditions</h4>
            </div>

            <FauxField
                name={"dataProtection"}
                label={"How we use your information"}
                required={true}>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              We never share your data with anyone outside of Swurf Ltd, please find our <a target={"_blank"} href={"https://swurf.co/privacy-policy/"}>privacy notice here</a>.
            </FauxField>

            <CheckboxList
              type={"checkbox"}
              name={"terms_and_conditions_accepted"}
              options={[{label: "I accept the terms and conditions", value: "true"}]}
              label={"Accept terms and conditions"}
              helpText={""}
              required={true}>
              <div className={"termsHelp"}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                Please review our payment <a href={"https://swurf.co/payment-terms/"} target={"_blank"}>term and conditions</a>
              </div>
            </CheckboxList>
          </fieldset>

          <div className={"field pageControls"}>
            <div>
              <button onClick={() => setSubmitAction("checkout")}>checkout</button>
            </div>
            {props.onCancel && (
              <div>
                <button className={"cancel"} onClick={(e) => {
                  props.onCancel()
                  e.preventDefault();
                }}>
                  cancel
                </button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
  );
}
