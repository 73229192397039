import {createSlice} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {client} from "../../api/client";
import {selectToken} from "../auth/authSlice";

export const createDataSlice = ({name, fetch}) => {
  const slice = createSlice({
    name: name,
    initialState: {
      isLoading: false,
      isLoaded: false,
      data: null,
    },
    reducers: {
      setLoading: state => {
        state.isLoading = true
      },
      setData: (state, action) => {
        state.data = action.payload
        state.isLoading = false
        state.isLoaded = true
      },
    }
  })

  const selectData = state => state[name].data
  const selectIsLoading = state => state[name].isLoading
  const selectIsLoaded = state => state[name].isLoaded

  let useData = () => {
    const dispatch = useDispatch()
    const data = useSelector(selectData)
    const isLoading = useSelector(selectIsLoading)
    const isLoaded = useSelector(selectIsLoaded)

    useEffect(() => {
      if (data === null)
        dispatch(fetch())
    }, [dispatch, data])

    return {[name]: data, isLoading, isLoaded}
  };
  slice.useData = useData
  return slice
}

const createSimpleDataSlice = ({name, endpoint}) => {
  const dataSlice = createDataSlice({
    name: name,
    fetch: () => async (dispatch, getState) => {
      console.log(`fetching ${name}`)
      dispatch(dataSlice.actions.setLoading())
      let response = await client.get(endpoint, selectToken(getState()))
      dispatch(dataSlice.actions.setData(response))
    }
  })
  return dataSlice
}

const categoriesSlice = createSimpleDataSlice({
  name: 'categories',
  endpoint: `hosts/categories/`,
})
export const useCategories = categoriesSlice.useData
export const categoriesReducer = categoriesSlice.reducer

const typesOfWorkSlice = createSimpleDataSlice({
  name: 'typesOfWork',
  endpoint: `hosts/types-of-work/`,
})
export const useTypesOfWork = typesOfWorkSlice.useData
export const typesOfWorkReducer = typesOfWorkSlice.reducer

const facilitiesSlice = createSimpleDataSlice({
  name: 'facilities',
  endpoint: `hosts/facilities/`,
})
export const useFacilities = facilitiesSlice.useData
export const facilitiesReducer = facilitiesSlice.reducer

const stringsSlice = createSimpleDataSlice({
  name: 'strings',
  endpoint: `users/strings/`,
})
export const useStrings = stringsSlice.useData
export const stringsReducer = stringsSlice.reducer
export const getString = (strings, name) => strings?.find((s) => s.name === name)?.text