import { FormProvider, useForm } from "react-hook-form";
import { Field } from "../../../form/Field";
import link from "../../../assets/link.png";
import facebook from "../../../assets/facebook.png";
import instagram from "../../../assets/instagram.png";
import linked from "../../../assets/linked.png";
import deleteIcon from "../../../assets/deleteIcon.png";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import "./Page10Promote.scss";
import { client } from "../../../api/client";
import { useBusiness } from "../../business/businessSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../auth/authSlice";
import { SwurfSpinner } from "../../../components/SwurfSpinner";
import { updateFormState } from "../hostSlice";
import { useHistory } from "react-router";

export function Promotion(props) {
  const host = props.host;
  const onSave = props.onSave;
  const business = useBusiness();

  const [showPopup, setShowPopup] = useState(false);
  const formMethods = useForm({
    defaultValues: props.defaultValues,
  });
  const [imageUploadError, setImageUploadError] = useState({
    titleError: false,
    imageError: false,
  });
  const [index, setIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitAction, setSubmitAction] = useState();
  const [assets, setAssets] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  // this used for add assets
  const addAsset = () => {
    let assetsLoading = [];
      if (assets.length > 0) {
        assetsLoading = assets?.filter((obj) => obj?.loading === true);
      }
      if(assetsLoading.length > 0){
        // do something
      }else{
        setShowPopup(true);
        setIndex(assets?.length);
      }
   
  };
  const token = useSelector(selectToken);
  const { handleSubmit } = formMethods;
  useEffect(() => {
    if (props.defaultValues.market_materials) {
      //set assets when there is saved data
      const fetchAssetsWithImagePaths = async () => {
        const updatedAssets = await Promise.all(
          props.defaultValues.market_materials?.map(async (element) => {
            const extension = element.file && getFileExtension(element.file);
            const imagePath = extension ? await loadImages(extension) : "";
            return { ...element, imagePath };
          }) || []
        );

        setAssets(updatedAssets);
      };

      fetchAssetsWithImagePaths();
    }
  }, []);

  const onSubmit = async (values) => {
    // filter market_materials from object to array of ids
    const filteredIds = assets
      ?.map((obj) => obj?.id)
      .filter((id) => id !== null);

    props.defaultValues.market_materials = filteredIds;

    const formData = await dispatch(updateFormState(host, props.defaultValues));
    if (submitAction === "back") {
      history.push("../page7/");
    } else if (submitAction === "save") {
      let assetsLoading = [];
      if (assets.length > 0) {
        assetsLoading = assets?.filter((obj) => obj?.loading === true);
      }
      if (assetsLoading.length > 0) {
        // do something if required
      } else {
        onSave?.(props?.defaultValues);
      }
    }
  };
  // to close popup if image uploading showing error
  const closeImageError = () => {
    const updatedAssets = [...assets];

    updatedAssets[index] = null;
    const filteredIds = updatedAssets.filter((id) => id !== null);
    setAssets(filteredIds);
    setShowPopup(false);
  };
  //when  title and image  both uploaded then submit assets
  const onAssetSubmit = async () => {
    const updatedAssets = [...assets];
    const formData = new FormData();
    const extension =
      updatedAssets[index]?.file &&
      updatedAssets[index]?.file[0]?.name &&
      getFileExtension(updatedAssets[index]?.file[0]?.name);

    const imagePath = extension ? await loadImages(extension) : "";
    updatedAssets[index] = {
      file: updatedAssets[index]?.file,
      title: updatedAssets[index]?.title,
      id: updatedAssets[index]?.id || null,
      imagePath: imagePath,
      loading: false,
    };
    if (updatedAssets[index]?.title?.trim() == "") {
      setImageUploadError({ titleError: true, imageError: false });
      return;
    } else if (!updatedAssets[index]?.file?.length) {
      setImageUploadError({ titleError: false, imageError: true });
      return;
    }
    formData.append("file", updatedAssets[index]?.file[0]);
    formData.append("title", updatedAssets[index]?.title);
    try {
      updatedAssets[index] = {
        file: updatedAssets[index]?.file,
        title: updatedAssets[index]?.title,
        id: updatedAssets[index]?.id || null,
        imagePath: imagePath,
        loading: true,
      };
      setAssets(updatedAssets);
      setShowPopup(false);
      //save image and title into api to getting id of market-material
      const response = await client.post(
        `hosts/businesses/${business.id}/market-material/`,
        formData,
        token
      );
      updatedAssets[index] = {
        file: response?.file,
        title: response.title,
        id: response?.id || null,
        imagePath: imagePath,
        loading: false,
      };
      //get array of ids from assets data array of object
      const filteredIds = updatedAssets
        .map((obj) => obj?.id)
        .filter((id) => id !== null);

      const updatedMarketMaterials = [...(filteredIds || [])];
      // update form state
      await dispatch(
        updateFormState(host, {
          ...props?.defaultValues,
          market_materials: updatedMarketMaterials,
        })
      );

      setAssets(updatedAssets);
    } catch (error) {
      //show image uploaded is not in this format jpeg, jpg, png, pdf, docx
      if (
        !updatedAssets[index]?.title ||
        updatedAssets[index]?.title?.trim() == ""
      ) {
        setImageUploadError({ titleError: true, imageError: false });
      } else {
        setImageUploadError({ titleError: false, imageError: true });
      }
    
      updatedAssets.splice(index, 1);

      setAssets(updatedAssets);
    }
  };

  const onChange = async (e, index) => {
    // common function for updating of text and images in assets section
    try {
      const updatedAssets = [...assets];
      const newArray = [...updatedAssets];

      if (e.target.type === "text") {
        const extension =
          newArray[index]?.file &&
          newArray[index]?.file[0]?.name &&
          getFileExtension(newArray[index]?.file[0]?.name);
        const imagePath = extension ? await loadImages(extension) : "";
        // updating text section
        newArray[index] = {
          title: e.target.value,
          file: newArray[index]?.file || undefined,
          id: newArray[index]?.id || null,
          imagePath: imagePath,
        };
      } else {
        const files = Array.from(e.target.files);
        // updating image section
        if (files?.length) {
          const extension = files[0]?.name && getFileExtension(files[0]?.name);
          const imagePath = extension ? await loadImages(extension) : "";
          newArray[index] = {
            title: newArray[index]?.title ?? "",
            file: files,
            id: newArray[index]?.id || null,
            imagePath: imagePath,
          };
        }
      }
      setAssets(newArray);
    } catch (error) {
      // Error handling
 

      const updatedAssets = [...assets];
      updatedAssets[index] = {
        title: updatedAssets[index]?.title,
        file: updatedAssets[index]?.file,
        loading: false,
        id: updatedAssets[index]?.id || null,
      };
    }
  };
  //remove images from assets section
  const removeImage = async (index) => {
    setDeleteIndex(index);
    setShowDeletePopup(true);
  };
  //delete image from indexing when say yes delete
  const confirmbtn = async () => {
    const updatedAssets = [...assets];
    updatedAssets[deleteIndex] = null;

    const filteredIds = updatedAssets.filter(
      (id) => id !== null && id !== undefined
    );

    const updatedMarketMaterials = filteredIds || [];

    const formStateUpdate = {
      ...props?.defaultValues,
      market_materials: updatedMarketMaterials,
    };
    //update form state
    await dispatch(updateFormState(host, formStateUpdate));

    const updatedArr = updatedAssets.filter((id) => id !== null);
    setAssets(updatedArr);

    setShowDeletePopup(false);
  };
  // for updating social text facebook,instagram,linked-in,link input field
  const inputHandler = (e) => {
    props.defaultValues[e.target.name] = e.target.value;
    dispatch(updateFormState(host, props.defaultValues));
  };
  //load images from server
  async function loadImages(extension) {
    try {
      if (extension) {
        // Use dynamic import for images
        const imagePath = await import(`../../../assets/${extension}.png`).then(
          (module) => module.default
        );

        return imagePath;
      } else {
        // Default image for other file types
        return require(`../../../assets/file.png`).default;
      }
    } catch (error) {
    
      // Handle the error, maybe return a default image or null
      return null;
    }
  }
  // to show which type of image is uploading
  function getFileExtension(filename) {
    if (!filename) {
      return null; // Handle the case where the filename is null or undefined
    }
    const lastDotIndex = filename.lastIndexOf(".");

    if (lastDotIndex !== -1 && lastDotIndex < filename.length - 1) {
      // Check if there is at least one character after the last dot
      return filename.slice(lastDotIndex + 1);
    } else {
      return null; // Handle the case where there is no dot or the dot is at the end of the filename
    }
  }
  // open image into new tab when click on asset section div
  const openImageInNewTab = (imagePath) => {
    window.open(imagePath, "_blank");
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          className={"form Page10Promotion"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={"formSection mainSection"}>
            <fieldset className={"formSection promoteFieldSet"}>
              {/* Social section start */}
              <h3 className={"withDescription"}>
                Enter your social media handles for your business
              </h3>
              <div className={"pageDescription"}>
                This may be used by the swurf team for promoting your business
              </div>
              <div class="social-links">
                <div class="social-input">
                  <Field
                    type={"text"}
                    name={"instagram_handle"}
                    logoSrc={instagram}
                    helpText={""}
                    required={false}
                    onChange={(e) => inputHandler(e)}
                  />
                  <div class="fa fa-circle"></div>
                </div>
                <div class="social-input">
                  <Field
                    type={"text"}
                    helpText={""}
                    required={false}
                    name={"linkedin_handle"}
                    logoSrc={facebook}
                    onChange={(e) => inputHandler(e)}
                  />
                </div>
                <div class="social-input">
                  <Field
                    type={"text"}
                    name={"facebook_handle"}
                    helpText={""}
                    required={false}
                    logoSrc={linked}
                    onChange={(e) => inputHandler(e)}
                  />
                </div>
                <div class="social-input">
                  <Field
                    name={"link_handle"}
                    type={"text"}
                    helpText={""}
                    required={false}
                    logoSrc={link}
                    onChange={(e) => inputHandler(e)}
                  />
                </div>
              </div>
              {/* Asset Section start */}
              <div className="assetsSection">
                <h3 className={"withDescription"}>Marketing Material</h3>
                <div className={"pageDescription"}>
                  You may upload marketing material below in order for the Swurf
                  team to promote your business.
                  <br />
                  <br />
                  Accepted file types include jpeg,png,pdf,docx
                </div>
                {/* Asset group image section start */}
                <div className={"groupImage asset-card-main"}>
                  {assets?.length > 0 ? (
                    assets.map((asset, index) => {
                      const title = asset?.title;
                      const filename = asset.file;

                      return (
                        <div className={"fieldInput imageContainer"}>
                          <div className={"image asset-card"}>
                            <SwurfSpinner
                              loading={asset?.loading ? asset?.loading : ""}
                            />

                            <div className="asset-card-inner">
                              <div
                                className="bar"
                                onClick={() => openImageInNewTab(filename)}
                              >
                                <div className="uploadBox">
                                  <div
                                    className="upload"
                                    style={{
                                      backgroundImage: `url(${asset?.imagePath})`,
                                      color: "blue",
                                      backgroundSize: "cover",
                                    }}
                                  ></div>
                                  <label>
                                    {asset?.file ? "Change image" : "Add image"}
                                    <input
                                      type="file"
                                      onChange={(e) => onChange(e, index)}
                                      className="image_uploader"
                                    />
                                  </label>
                                </div>

                                <div className="title">{title}</div>
                              </div>

                              <div
                                className="deleteAsset"
                                onClick={() => removeImage(index)}
                              >
                                <img src={deleteIcon} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={"blank-area"}></div>
                  )}

                  <div className={"field pageControls"}>
                    <button onClick={() => addAsset()} type="">
                      Add Asset
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          {/* Page Control section */}
          <div className={"form Page10Promotion"}>
            <div className={"field pageControls"}>
              <div className="btn-grp">
                <button onClick={() => setSubmitAction("back")}>back</button>
                <button onClick={() => setSubmitAction("save")}>
                  {host ? "save" : "next"}
                </button>
              </div>
              {props.onCancel && (
                <div>
                  <button
                    className={"cancel"}
                    onClick={(e) => {
                      props.onCancel();
                      e.preventDefault();
                    }}
                  >
                    cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>

        {/* Asset modal with input and image uploader */}
        <Modal
          isOpen={showPopup}
          style={{
            overlay: {
              backgroundColor: "#00000055",
            },
          }}
          className="asset-image-upload"
        >
          <div className={"promotionPrompt asset-card-main"}>
            <div className={"fieldInput imageContainer"}>
              <div className={"image asset-card"}>
                <div className="asset-card-inner-popup">
                  <div className="bar">
                    <div className="uploadBox">
                      <div
                        className="upload"
                        style={{
                          color: "blue",
                          backgroundSize: "cover",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            assets[index]?.imagePath ||
                            require("../../../assets/upload.png")
                          }
                          alt="Uploaded Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                        {/* Your content */}
                      </div>
                      <SwurfSpinner loading={assets[index]?.loading ?? ""} />
                      <label>
                        {assets[index]?.file ? "Change image" : "Add image"}
                        <input
                          type="file"
                          onChange={(e) => onChange(e, index)}
                          className="image_uploader"
                          required={true}
                        />
                      </label>
                    </div>

                    <div className="title">
                      <Field
                        name={"title"}
                        type={"text"}
                        helpText={""}
                        required={true}
                        placeholder={"Asset Title"}
                        onChange={(e) => onChange(e, index)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"buttons pageControls"}>
              <button onClick={() => onAssetSubmit()}>Submit</button>
              <button onClick={() => closeImageError()}>Close</button>
            </div>
          </div>
        </Modal>
        {/* image uploading error modal*/}
        <Modal
          isOpen={imageUploadError?.imageError || imageUploadError?.titleError}
          style={{
            overlay: {
              backgroundColor: "#00000055",
            },
          }}
        >
          <div className={"promotionPrompt"}>
            <h1>Something went wrong</h1>
            {imageUploadError?.titleError ? (
              <p>The title field is required</p>
            ) : (
              <p>There was an issue uploading the file you provided.</p>
            )}
            <div className={"buttons"}>
              <button onClick={() => setImageUploadError(false)}>Close</button>
            </div>
          </div>
        </Modal>
        {/* Delete asset modal */}
        <Modal
          isOpen={showDeletePopup}
          style={{
            overlay: {
              backgroundColor: "#00000055",
            },
          }}
        >
          <div className={"promotionPrompt"}>
            <h1>Hey, wait a minute.</h1>
            <p>are you sure you want to delete assets ?</p>
            <div className={"buttons"}>
              <button onClick={() => setShowDeletePopup(false)}>
                Skip for now
              </button>
              <button onClick={() => confirmbtn()}> Yes , delete</button>
            </div>
          </div>
        </Modal>
      </FormProvider>
    </>
  );
}
