import React, {useState} from "react";
import {performPasswordReset, selectPasswordResetErrors} from "./authSlice";
import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useForm} from "react-hook-form";
import {ErrorMessage} from '@hookform/error-message';
import {handleServerSubmit, useServerErrors} from "../../form/formErrors";
import {Field} from "../../form/Field";
import {Link} from "react-router-dom";
import {useQuery} from "../../util/query";

export function PasswordReset() {
  const dispatch = useDispatch()
  const formMethods = useForm()
  const {errors, setError} = formMethods
  const apiErrors = useSelector(selectPasswordResetErrors)
  useServerErrors(setError, apiErrors)
  const [complete, setComplete] = useState(false)

  const onSubmit = async values => {
    let response = await dispatch(performPasswordReset(values.email))
    if (response)
      setComplete(true)
  }

  const next = useQuery().get('next') || '/'

  return (
      <FormProvider {...formMethods}>
        <form className={"PasswordReset"} onSubmit={handleServerSubmit(formMethods, onSubmit)}>
          <h2>Forgotten<br />Password</h2>
          {complete ? (
              <div className={"authNav"}>
                Check your emails for a password reset link.
              </div>
          ) : (
              <React.Fragment>
                <div className={"authNav"}>
                  Let's get your password changed!<br />
                  Enter your registered email below to reset your password.
                </div>
                <ErrorMessage className="error-message" name="non_field_errors" as="div" errors={errors}/>
                <Field
                    type={"email"}
                    label="Email"
                    name={"email"}
                    required={true}
                />
                <div className={"field"}>
                  <button>Reset password</button>
                </div>
              </React.Fragment>
          )}

          <div className={"authNav"}><Link to={`/login/?next=${next}`}>Back to login</Link></div>
        </form>
      </FormProvider>
  );
}
