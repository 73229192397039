import React from "react";
import "./ContentNavBar.scss"
import {useSelector} from "react-redux";
import {selectUser} from "../auth/authSlice";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

export const ContentNavBar = ({title, subtitle, hamburgerClick}) => {
  const user = useSelector(selectUser)

  return (
      <div className={"ContentNavBar"}>
        <div className={"hamburgerButton"} onClick={hamburgerClick} />
        <div className={"title"}>
          <h2>{title}</h2>
          {subtitle && (
              <div className={"subtitle"}>{subtitle}</div>
          )}
        </div>
        <div className={"userInfo"}>
          <div><Link to={"/account/"}>{user.name}</Link></div>
        </div>
      </div>
  )
}

ContentNavBar.propTypes = {
  hamburgerClick: PropTypes.func.isRequired,
};
