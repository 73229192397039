import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import "./Field.scss";
import { useFormContext } from "react-hook-form";

export const Field = ({
  label,
  placeholder,
  name,
  autocomplete,
  helpText,
  type = "text",
  required = false,
  onChange,
  inputProps = {},
  registerOptions = {},
  logoSrc = "",
  readOnly
}) => {
  const { errors, register } = useFormContext();
  if (required && registerOptions.required === undefined)
    registerOptions.required =
      required === true ? "This field is required" : required;

  if (type === "email") {
    type = "text";
    if (registerOptions.pattern === undefined)
      registerOptions.pattern = {
        value: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address",
      };
    if (registerOptions.autocomplete === undefined)
      registerOptions.autocomplete = "email";
  } else if (type === "number") {
    registerOptions.valueAsNumber = true;
  }

  return (
    <div
      className={`field ${errors.name ? "error" : ""} ${
        required ? "required" : ""
      } ${type}Field field_${name}`}
    >
      <label>
        <span className={"fieldLabel"}>
          <span className={"labelText"}>{label}</span>
          {helpText && <span className={"helpText"}>{helpText}</span>}
        </span>
        <span className={"fieldInput"}>
          <div class="input-container">
            {logoSrc && (
              <img
                src={logoSrc}
                alt="Logo"
                className="logo"
              />
            )}
            <input
              type={type}
              readOnly={readOnly}
              autoComplete={autocomplete}
              aria-label={label}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              ref={register(registerOptions)}
              {...inputProps}
            />
          </div>
        </span>
      </label>
      <ErrorMessage
        className="fieldError"
        name={name}
        as="div"
        errors={errors}
      />
    </div>
  );
};
